export const DESCRIPTIONS = [
  {
    id: "ourVision",
    paragraphs: ["paragraph1"],
    src: "ourVisionTitle.png",
  },
  {
    id: "ourMission",
    paragraphs: ["paragraph1"],
    src: "ourMissionTitle.png",
  },
];
