import styled from "styled-components";
import contactUsBackground from "assets/images/Home/ContactUs/contactUsBackground.png";
import contactUsPhoneBackground from "assets/images/Home/ContactUs/contactUsPhoneBackground.png";

export const ContactUsContainer = styled.div`
  width: 100%;
  background-image: url(${contactUsBackground});
  color: white;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 7.5%;

  @media (max-width: 768px) {
    background-image: url(${contactUsPhoneBackground});
  }
`;

export const MessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageText = styled.p`
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 15px;
    text-align: center;
    width: 80%;
  }
`;

export const TitleImage = styled.img`
  width: 13%;
  background-size: 100% 100%;
  margin-bottom: 1.5%;
  min-width: 200px;
  @media (max-width: 768px) {
    width: 42rem;
    min-width: 0;
  }
`;

export const OvioLogo = styled.img`
  width: 8%;
  margin-top: 3%;
  margin-bottom: 1%;
  @media (max-width: 768px) {
    width: 30%;
  }
`;

export const InputContainer = styled.div`
  padding: 1.3%;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const ErrorText = styled.p`
  color: rgba(255, 55, 127, 1);
  font-size: 13px;
  margin-left: 2px;
`;

export const FieldsContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
