import {
  PartnersContainer,
  PartnerLogo,
  PartnersTypeContainer,
  PartnersTypeTitle,
  PartnersLogoRow,
} from "./styles.tsx";
import { PARTNERS_DATA } from "./constants.tsx";

const Partners = () => {
  const renderPartners = () => {
    return PARTNERS_DATA.map((partnerData) => (
      <PartnersTypeContainer key={partnerData.id}>
        <PartnersTypeTitle>{partnerData.title}</PartnersTypeTitle>
        <PartnersLogoRow>
          {partnerData.partnersList.map(({ name, src }) => (
            <PartnerLogo
              key={name}
              src={require(`assets/images/Home/Partners/partnersLogos/${src}`)}
            />
          ))}
        </PartnersLogoRow>
      </PartnersTypeContainer>
    ));
  };

  return <PartnersContainer>{renderPartners()}</PartnersContainer>;
};

export default Partners;
