import { css, FlattenSimpleInterpolation } from "styled-components";
import { sizes } from "constants/Styles.ts";

type Media = {
  [key in keyof typeof sizes]: (
    ...args: FlattenSimpleInterpolation[]
  ) => FlattenSimpleInterpolation;
};

export const media: Media = Object.keys(sizes).reduce((acc, label) => {
  acc[label as keyof typeof sizes] = (
    first: any,
    ...args: FlattenSimpleInterpolation[]
  ) =>
    css`
      @media (max-width: ${sizes[label]}px) {
        ${css(first, ...args)}
      }
    `;
  return acc;
}, {} as Media);
