export const sizes: { [key: string]: number } = {
  desktop: 1200,
  tablet: 834, 
  ipadMini: 768,
  phone: 576,
  mdPhone: 370,
  smPhone: 300,
};

export const theme = {
  darkTheme: {
    colorBg: "#fff",
    mainFontColor: "#fff",
    secondaryFontColor: "#fcfcfc",
    disabledFontColor: "#aac0d5",
    bluishGreen: "#2fe0b8",
    lightBlueGrey: "#aac0d5",
    darkSlateGray: "#212932",
    midnightBlue: "#2a3643",
    slateBlue: "#3e4c5b",
    colorDisabled: "#586c83",
    lightGray: "#f4f5f5",
    placeholderGray: "#869bae",
    darkMidnightBlue: "#171d24",
  },
};

export const windowWidth = window.innerWidth;
export const windowHeight = window.innerHeight;
