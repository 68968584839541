import styled from "styled-components";
import { windowWidth } from "constants/windowSize.tsx";

export const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 900px;
  margin-top: 3.5%;
  justify-content: center;
  margin-top: 80px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TeamPersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 1);
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-left-color: rgba(64, 64, 64);
  border-right-color: rgba(64, 64, 64);
  border-top: rgba(0, 0, 0, 1);
  border-bottom: rgba(0, 0, 0, 1);
  width: 165px;
  height: 165px;
  margin-bottom: 80px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

export const TeamPersonContent = styled.div`
  display: flex;
  width: 85%;
  align-items: end;
  justify-content: end;
  position: relative;
`;

export const PersonImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 10px;
  margin-top: -50px;
  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`;

export const LinkedinLogo = styled.img`
  width: 40px;
  height: 40px;
  margin-top: -20px;
  /* width: ${windowWidth * 0.02}px;
  height: ${windowWidth * 0.02}px; */
  /* margin-top: -15%; */
`;

export const PersonDescription = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  text-align: start;
  margin-top: 9px;
  margin-left: 2px;
  top: 0;
  left: 0;
`;

interface TextProps {
  isNameText?: boolean;
}

export const Text = styled.p`
  font-size: 13px;

  ${({ isNameText }: TextProps) => {
    if (isNameText) {
      return { fontWeight: 900 };
    }
  }}
`;
