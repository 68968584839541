import styled from "styled-components";
import backgroundPartners from "assets/images/Home/Partners/backgroundPartners.png";

export const PartnersContainer = styled.div`
  width: 100%;
  background-image: url(${backgroundPartners});
  background-size: cover;
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  color: white;
  background-size: 100% 100%;
  padding-top: 1%;
  padding-bottom: 1%;
`;

export const PartnersTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PartnersTypeTitle = styled.p`
  text-align: center;
  font-size: 16px;
  letter-spacing: 3px;
  font-weight: 600;
  padding-top: 1%;
  padding-bottom: 0.3%;
`;

export const PartnersLogoRow = styled.div`
  display: flex;
  justify-content: center;
  width: 37%;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const PartnerLogo = styled.img`
  height: 12%;
  width: 12%;
  margin-left: 0.5%;
  @media (max-width: 768px) {
    margin-left: 5%;
    width: 22%;
  }
`;
