import {
  IntroductionContainer,
  JustGotBetterImage,
  StoreImage,
  DevicesImage,
  TitleContainer,
  StoreButton,
  StoreButtonsContainer,
} from "./styles.tsx";
import justGotBetter from "assets/images/Home/Introduction/JustGotBetter.png";
import JustGotBetterMobile from "assets/images/Home/Introduction/JustGotBetterMobile.png";
import googlePlay from "assets/images/Home/Introduction/googlePlay.png";
import appStore from "assets/images/Home/Introduction/appStore.png";
import devices from "assets/images/Home/Introduction/devices.png";
import { isMobileDevice } from "constants/windowSize.tsx";
import useAnalyticsEventTracker from "hooks/useAnalyticsEventTracker.tsx";

const Introduction = () => {
  const { gaEventTracker, actions } = useAnalyticsEventTracker("Footer");
  return (
    <IntroductionContainer>
      <TitleContainer>
        <JustGotBetterImage
          src={isMobileDevice ? JustGotBetterMobile : justGotBetter}
        />
        <StoreButtonsContainer>
          <StoreButton
            onClick={() => gaEventTracker({ action: actions.googlePlay })}
            href="https://ovio.onelink.me/qHtu/s2tu4gn9"
            target="_blank"
          >
            <StoreImage src={googlePlay} />
          </StoreButton>
          <StoreButton
            onClick={() => gaEventTracker({ action: actions.appStore })}
            href="https://ovio.onelink.me/qHtu/lh7b3gfn"
            target="_blank"
          >
            <StoreImage src={appStore} />
          </StoreButton>
        </StoreButtonsContainer>
      </TitleContainer>
      <DevicesImage src={devices} />
    </IntroductionContainer>
  );
};
export default Introduction;
