import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const OvioLogo = styled.img`
  width: 7%;
  margin-left: 10%;
`;

export const CoinImg = styled.img`
  width: 9%;
  position: absolute;
  margin-top: -3rem;
  margin-left: 3rem;
  min-width: 100px;
`;
