import {
  OurProductsContainer,
  TitleImage,
  Card,
  CardTextContainer,
  ParagraphText,
  CardTitle,
  DeviceImage,
  CardTextAndButton,
  TextButtonContainer,
} from "./styles.tsx";
import title from "assets/images/Home/OurProducts/title.png";
import { PRODUCTS_CARD_DATA } from "./constants.tsx";
import { text } from "localization/en.tsx";
import { isMobileDevice } from "constants/windowSize.tsx";
import TextButton from "components/Buttons/TextButton.tsx";
import { useNavigate } from "react-router-dom";

interface IProps {
  scrollToContactUs: () => void
}

const OurProducts = ({scrollToContactUs}: IProps) => {
  const navigate = useNavigate();
  const handleClick = (redirectPage: string) => {
    if (redirectPage) {
      navigate(redirectPage);
    } else {
      scrollToContactUs();
    }
  };

  const renderCards = () => {
    return PRODUCTS_CARD_DATA.map(
      ({
        id,
        isLeftSideCard,
        textParagraphs,
        deviceSrc,
        deviceForMobileSrc,
        buttonText,
        redirectPage,
      }) => {
        return (
          <Card isLeftSideCard={isLeftSideCard} key={id}>
            <CardTextAndButton>
              <CardTextContainer>
                <CardTitle>
                  {text.pages.home.sections.ourProducts[id].title}
                </CardTitle>
                {isMobileDevice ? (
                  <ParagraphText>
                    {text.pages.home.sections.ourProducts[id].paragraphForMobile}
                  </ParagraphText>
                ) : (
                  textParagraphs.map((paragraph) => {
                    return (
                      <ParagraphText>
                        {text.pages.home.sections.ourProducts[id][paragraph]}
                      </ParagraphText>
                    );
                  })
                )}
              </CardTextContainer>
              <TextButtonContainer textLength={buttonText.length}>
                <TextButton
                  customTextStyles={isMobileDevice 
                    ? {fontSize: "3rem" }
                    : { fontSize: "1rem" }}
                  text={buttonText}
                  onClick={() => handleClick(redirectPage)}
                  isFullWidth={true}
                />
              </TextButtonContainer>
            </CardTextAndButton>
            <DeviceImage
              isLeftSideCard={isLeftSideCard}
              src={require(`assets/images/Home/OurProducts/${
                isMobileDevice && deviceForMobileSrc
                  ? deviceForMobileSrc
                  : deviceSrc
              }`)}
            />
          </Card>
        );
      }
    );
  };

  return (
    <OurProductsContainer id={"ourProducts"}>
      <TitleImage src={title} />
      {renderCards()}
    </OurProductsContainer>
  );
};

export default OurProducts;
