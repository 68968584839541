export const PRODUCTS_CARD_DATA = [
  {
    id: "uaEngine",
    isLeftSideCard: true,
    buttonText: "Learn More",
    textParagraphs: ["paragraph1", "paragraph2", "paragraph3"],
    redirectPage: "/cpeFlow",
    deviceSrc: "uaEngineDevice.png",
  },
  {
    id: "wallet",
    isLeftSideCard: false,
    buttonText: "Learn More",
    textParagraphs: ["paragraph1", "paragraph2"],
    redirectPage: "/wallet",
    deviceSrc: "leftSideWalletDevice.png",
    deviceForMobileSrc: "rightSideWalletDevice.png",
  },
  {
    id: "crossGameCurrency",
    isLeftSideCard: true,
    buttonText: "Early adopters access",
    textParagraphs: ["paragraph1", "paragraph2"],
    deviceSrc: "crossGameCurrencyDevice.png",
  },
];
