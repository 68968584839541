import { HomeContainer, CoinImg } from "./styles.tsx";
import Introduction from "./Sections/Introduction/Introduction.tsx";
import Partners from "./Sections/Partners/Partners.tsx";
import OurProducts from "../Home/Sections/OurProducts/OurProducts.tsx";
import ContactUs from "./Sections/ContactUs/ContactUs.tsx";
import { isMobileDevice } from "../../constants/windowSize.tsx";

const Home = () => {

  const scrollToContactUs = () => {
    const element = document.getElementById("contactUs");
    element?.scrollIntoView({ behavior: "smooth" });
  }
  
  return (
    <HomeContainer id="home">
      <Introduction />
      <Partners />
      {!isMobileDevice && (
        <CoinImg src={require("../../assets/images/Home/coin.png")} />
      )}
      <OurProducts scrollToContactUs={scrollToContactUs}/>
      <ContactUs />
    </HomeContainer>
  );
};

export default Home;
