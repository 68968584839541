import { HeaderButtons } from "./types.tsx";
const { HOME, OUR_PRODUCTS, ABOUT_US, CONTACT_US } = HeaderButtons;

export const HEADER_BUTTONS_DATA = {
  [HOME]: {
    text: "HOME",
    redirectPage: "/",
    scrollToComponent: "home",
  },
  [OUR_PRODUCTS]: {
    text: "OUR PRODUCTS",
    scrollToComponent: "ourProducts",
    redirectPage: "/",
  },
  [ABOUT_US]: {
    text: "ABOUT US",
    redirectPage: "/about",
  },
  [CONTACT_US]: {
    text: "CONTACT US",
    scrollToComponent: "contactUs",
    redirectPage: "/",
  },
};
