import {
  CpeFlowContainer,
  IntegratedPartnersBar,
  Title,
  Card,
  CardsContainer,
  Text,
  IntegratedPartnersImg,
} from "./styles.tsx";
import { CFE_FLOW_CARDS_SRC } from "./constants.tsx";

const CpeFlow = () => {
  return (
    <CpeFlowContainer>
      <Title src={require("assets/images/CpeFlow/title.png")}></Title>
      <Text>(COST PER ENGAGEMENT)</Text>
      <CardsContainer>
        {CFE_FLOW_CARDS_SRC.map((src) => {
          return <Card src={require(`assets/images/CpeFlow/${src}`)} />;
        })}
      </CardsContainer>
      <IntegratedPartnersBar>
        <Text style={{ marginTop: "1rem" }}>INTEGRATED PARTNERS:</Text>
        <IntegratedPartnersImg
          src={require("../../assets/images/CpeFlow/integratedPartners.png")}
        />
      </IntegratedPartnersBar>
    </CpeFlowContainer>
  );
};

export default CpeFlow;
