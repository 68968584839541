import styled from "styled-components";
import walletBackground from "assets/images/Wallet/walletBackground.png";
import walletPhoneBackground from "assets/images/Wallet/walletPhoneBackground.png";
import { media } from '../../utils/styledComponent.ts';

interface ITextProps {
  shouldHaveMarginBottom: boolean;
}

export const WalletContainer = styled.div`
  width: 100%;
  background-image: url(${walletBackground});
  background-size: 100% 100%;
  background-position: center;
  padding-top: 150px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5%;

  ${media.ipadMini`
    background-image: url(${walletPhoneBackground});
    padding-top: 100px;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  letter-spacing: 5px;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 40px;
  text-transform: uppercase;

  ${({ shouldHaveMarginBottom }: ITextProps) => {
      if (shouldHaveMarginBottom) {
        return { marginBottom: '80px' };
      }
    }}

  ${media.tablet`
    letter-spacing: 5px;

    ${({ shouldHaveMarginBottom }: ITextProps) => {
      if (shouldHaveMarginBottom) {
        return { marginBottom: '50px' };
      }
    }}
  `}

  ${media.ipadMini`
    font-size: 12px;
    letter-spacing: 5px;

    ${({ shouldHaveMarginBottom }: ITextProps) => {
      if (shouldHaveMarginBottom) {
        return { marginBottom: '30px' };
      }
    }}
  `}
`;

export const Title = styled.img`
  width: 22rem;
  min-width: 100px;

  ${media.tablet`
    width: 45rem;
    margin-bottom: 5px;
    min-width: 0px;
  `}

  ${media.ipadMini`
    width: 45rem;
    margin-bottom: 5px;
  `}
`;

export const SubTitle = styled(Text)`
  font-size: 22px;
  margin-top: 8px;
  letter-spacing: 10px;
  margin-bottom: 100px;

  ${media.tablet`
    font-size: 30px;
    letter-spacing: 10px;
  `}

  ${media.ipadMini`
    font-size: 14px;
    letter-spacing: 5px;
    margin-bottom: 50px;
  `}
`;

export const WalletStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
`;

export const StepsCards = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;

  ${media.tablet`
    width: 90%;
  `}

  ${media.ipadMini`
    width: 90%;
    margin-bottom: 60px;
  `}
`;

export const StepCardImg = styled.img`
  width: 17rem;
  height: 34rem;
  filter: drop-shadow(10px 10px 5px rgba(23, 29, 36, 0.4));
  
  ${media.tablet`
    width: 25rem;
    height: 50rem;
  `}

  ${media.ipadMini`
    width: 20rem;
    height: 40rem;
  `}
`;

export const SdkDocLink = styled.a`
  text-decoration: underline;
  margin-top: 5rem;
  cursor: pointer;

  > span {
    font-size: 20px;
  }

  ${media.ipadMini`
    margin-top: 0rem;

    > span {
      font-size: 12px;
    }
  `}
`;
