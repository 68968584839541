import { TEAM_PERSONS } from "./constants.tsx";
import {
  TeamContainer,
  PersonImage,
  TeamPersonContainer,
  LinkedinLogo,
  PersonDescription,
  TeamPersonContent,
  Text,
} from "./styles.tsx";

const Team = () => {
  const handleTeamPersonClicked = (linkedin: string) => {
    window.open(
      "https://www.linkedin.com/in/" + linkedin,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <TeamContainer>
      {TEAM_PERSONS.map(({ src, job, name, linkedin }) => {
        return (
          <TeamPersonContainer
            onClick={() => handleTeamPersonClicked(linkedin)}
          >
            <PersonImage src={require(`assets/images/About/Team/${src}`)} />
            <TeamPersonContent>
              <PersonDescription>
                <Text isNameText={true}>{name}</Text>
                <Text>{job}</Text>
              </PersonDescription>
              <LinkedinLogo
                src={require("assets/images/About/linkedinLogo.png")}
              />
            </TeamPersonContent>
          </TeamPersonContainer>
        );
      })}
    </TeamContainer>
  );
};

export default Team;
