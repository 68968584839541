import styled from "styled-components";
import background from "assets/TemporaryPage/background.png";
import { media } from "utils/styledComponent.ts";

export const PageContainer = styled.div`
  display: flex;
  height: calc(100vh + 5px);
  margin-top: -5px;
  align-items: center;
  flex-direction: column;
  background-position: center;
  padding: 20px 20px 80px 20px;
  background: url(${background});
  background-size: cover;
  justify-content: space-between;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Logo = styled.img``;

export const MainContent = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 60px);

  ${media.tablet`
    margin: 0 60px;
  `}
`;

export const MainText = styled.h1`
  color: #fff;
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

  ${media.desktop`
    font-size: 4.5rem;
  `}
  ${media.tablet`
    font-size: 5rem;
  `}
  ${media.phone`
    font-size: 6rem;
  `}
`;

export const SubText = styled.p`
  color: white;
  margin-top: 80px;
  text-align: center;
  font-size: 2.25rem;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

  ${media.desktop`
    font-size: 2.75rem;
  `}
  ${media.tablet`
    font-size: 3.25rem;
  `}
  ${media.phone`
    font-size: 4.25rem;
  `}
`;

export const ProgressBarContainer = styled.div`
  width: 50%;
  min-width: 400px;
  margin-top: 20px;
  position: relative;
  margin-bottom: 100px;

  ${media.tablet`
    width: 100%;
    min-width: auto;
  `}
`;

export const UnfilledLayer = styled.div`
  width: 100%;
  height: 15px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 2px 0px 0px #00000040;
  background: linear-gradient(
      90deg,
      rgba(4, 253, 254, 0.2) 0%,
      rgba(4, 253, 254, 0) 18.21%
    ),
    linear-gradient(0deg, rgba(170, 192, 213, 0.15), rgba(170, 192, 213, 0.15));
`;

export const FilledLayer = styled.div`
  top: 0;
  left: 0;
  height: 15px;
  position: absolute;
  border-radius: 8px;
  background: #9ae612;
  background-blend-mode: darken;
`;

export const FilledLayerTopLayer = styled.div`
  width: 100%;
  height: 17px;
  border-radius: 8px;
  position: absolute;
  background: linear-gradient(0deg, #2cb84b 0%, rgba(255, 255, 255, 0) 100%);
`;

export const InputContainer = styled.div`
  ${media.tablet`
    width: 70%;
  `}
  ${media.phone`
    width: 100%;
  `}
`;

export const Input = styled.input`
  color: white;
  padding: 18px;
  min-height: 72px;
  min-width: 400px;
  font-size: 1.3rem;
  margin-right: 24px;
  border-radius: 2000px;
  border: 2px solid white;
  background-color: transparent;

  &::placeholder {
    color: #dceaed;
  }
  ${media.desktop`
    font-size: 2rem;
  `}

  ${media.tablet`
    min-width: auto;
    margin-right: 24px;
    font-size: 2.125rem;
    width: calc(100% - 24px);
  `}
  ${media.phone`
    width: 100%;
    margin-right: 0;
    font-size: 3.125rem;
    align-items: center;
  `}
`;

export const InputMessageContainer = styled.div`
  min-height: 30px;
`;

export const Button = styled.button`
  gap: 8px;
  color: white;
  border: none;
  cursor: pointer;
  min-height: 72px;
  padding: 18px 42px;
  position: relative;
  border-radius: 28px;
  border-radius: 28px;
  background: #12e6e6;
  background-blend-mode: darken;

  &:hover {
    background: #0ebaba;
  }

  &:active {
    background: #0a7f7f;
  }

  ${media.tablet`
    width: 30%;
    padding: 18px 22px;
  `}
  ${media.phone`
    width: 70%;
  `}
`;

export const ButtonShadowLayer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 28px;
`;

export const ProgressIndicatorContainer = styled.div`
  color: #fff;
  display: flex;
  margin-top: 9px;
  font-size: 1.5rem;
  align-items: center;
  justify-content: space-between;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

  ${media.desktop`
    font-size: 2rem;
  `}
  ${media.tablet`
    font-size: 2.5rem;
  `}
  ${media.phone`
    font-size: 3.5rem;
  `}
`;

export const ProgressIndicator = styled.span`
  font-size: 1.125rem;

  ${media.desktop`
    font-size: 1.5rem;
  `}
  ${media.tablet`
    font-size: 1.75rem;
  `}
  ${media.phone`
    font-size: 2rem;
  `}
`;

export const FooterContainer = styled.footer`
  color: white;
  margin-top: 10px;
  margin-bottom: 50px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  position: relative;
  letter-spacing: 0px;

  ${media.desktop`
    font-size: 1.75rem;
  `}
  ${media.phone`
    font-size: 3rem;
    width: 90%
  `}
`;

export const FooterLink = styled.a`
  color: white;
  margin: 0px 10px;
  font-size: 1rem;
  text-decoration: none;

  ${media.desktop`
    font-size: 1.75rem;
  `}
  ${media.phone`
    font-size: 3.25rem;
  `}
`;

export const FooterText = styled.span`
  margin: 0px 10px;
  font-size: 1.25rem;

  ${media.desktop`
    font-size: 1.75rem;
  `}
  ${media.phone`
    font-size: 3rem;
  `}
`;

export const ButtonText = styled.span`
  position: relative;
  text-shadow: 0px 1px 0px #340274;
  text-stroke: 0.5px #023d74;
  font-family: Poppins;
  -webkit-text-stroke: 0.5px #023d74;
  font-weight: 700;
  text-align: center;
  font-size: 1.3rem;

  ${media.desktop`
    font-size: 2rem;
  `}
  ${media.tablet`
    width: 30%;
    font-size: 1.65rem;
  `}
  ${media.phone`
    width: 70%;
    font-size: 3.5rem;
  `}
`;

export const InputErrorMessage = styled.div`
  margin-top: 2rem;
  color: #ffabe8;
  font-family: Poppins;
  font-size: 1.125rem;

  ${media.desktop`
    font-size: 1.5rem;
  `}
  ${media.tablet`
    font-size: 2rem;
  `}
  ${media.phone`
    font-size: 3rem;
  `}
`;

export const InputResponseMessage = styled(InputErrorMessage)`
  color: #fff;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  position: relative;
  align-items: start;
  justify-content: center;

  ${media.phone`
    gap: 20px 0;
    align-items: center;
    flex-direction: column;
  `}
`;

export const SuccessText = styled.div`
  text-align: center;
  max-width: 500px;
  font-family: Poppins;
  color: white;
  font-size: 1.5rem;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

  ${media.tablet`
    font-size: 2.5rem;
  `}
  ${media.phone`
    font-size: 3.25rem;
  `}
`;

export const VerticalSpacer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
