import { InternalButton, ExternalButton, Text } from "./styles.tsx";

interface Props {
  onClick: () => {};
  text: string;
  isDisable?: boolean;
  customStyles?: any;
  customTextStyles?: any;
  isFullWidth?: boolean
}

const TextButton = ({
  text,
  isDisable,
  onClick,
  customStyles,
  customTextStyles,
  isFullWidth = false,
}: Props) => {
  return (
    <ExternalButton
      style={customStyles}
      isDisable={isDisable}
      onClick={() => {
        if (!isDisable) {
          onClick();
        }
      }}
      isFullWidth={isFullWidth}
    >
      <InternalButton>
        <Text style={customTextStyles}>{text}</Text>
      </InternalButton>
    </ExternalButton>
  );
};

export default TextButton;
