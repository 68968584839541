import { Section, Column, ContentImg } from '../style.js'
import { FooterWrapper, Row, SocialNav, StayConnectedTitle, Seperator, SmallText } from './style.js';

import { ReactComponent as FacebookIcon } from "assets/images/landing/facebookIcon.svg";
import { ReactComponent as InstagramIcon } from "assets/images/landing/instagramIcon.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/landing/linkedinIcon.svg";
import { ReactComponent as TwitterIcon } from "assets/images/landing/twitterIcon.svg";

import googlePlayImg from "assets/images/landing/googlePlay.png";
import { Link } from 'react-router-dom';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker.tsx';

function Footer() {
    const {gaEventTracker, actions} = useAnalyticsEventTracker('Footer');

    return (
    <footer> 
        <Section>
            <FooterWrapper>
                    <Column style={{ padding: '30px' }}>
                            <Row style={{justifyContent: 'center'}}>
                                <a onClick={() => gaEventTracker({ action: actions.googlePlay })} href="https://ovio.onelink.me/qHtu/s2tu4gn9">
                                    <ContentImg src={googlePlayImg}/>
                                </a>
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                <StayConnectedTitle>Stay Connected!</StayConnectedTitle>
                            </Row>
                            <Row style={{justifyContent: 'center'}}>
                                {/* <FooterNav style={{ flex: '1 1 30%' }}>
                                    <ul>
                                        <li><a href="#">Games</a></li>
                                        <li><a href="#">Crypto</a></li>
                                        <li><a href="#">Game  Coins</a></li>
                                        <li><a href="#">Crypto Us</a></li>
                                        <li><a href="#">NFT</a></li>
                                    </ul>
                                </FooterNav>
                                
                                <FooterNav style={{ flex: '1 1 30%' }}>
                                    <ul>
                                        <li><a href="#about">About</a></li>                
                                        <li><a href="#">The Team</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#contact">Contact Us</a></li>
                                        <li><a href="#">FAQ</a></li>
                                    </ul>
                                </FooterNav> */}

                                <div>                        
                                    <SocialNav>
                                        <ul>                                             
                                            <li>
                                                <a onClick={() => gaEventTracker({ action: actions.linkedin })} target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/oviogg"><LinkedinIcon/></a>
                                            </li>
                                            <Seperator/>
                                            <li>
                                                <a onClick={() => gaEventTracker({ action: actions.twitter })} target="_blank" rel="noreferrer" href="https://twitter.com/ovioGG"><TwitterIcon/></a>
                                            </li>
                                            <Seperator/>
                                            <li>
                                                <a onClick={() => gaEventTracker({ action: actions.facebook })} target="_blank" rel="noreferrer" href="https://www.facebook.com/oviogg"><FacebookIcon/></a>
                                            </li>  
                                            <Seperator/>
                                            <li>
                                                <a onClick={() => gaEventTracker({ action: actions.instagram })} target="_blank" rel="noreferrer" href="https://www.instagram.com/ovio.gg"><InstagramIcon/></a>
                                            </li>
                                        </ul>
                                    </SocialNav> 
                                </div>
                            </Row>
                            <Row style={{ justifyContent: 'center', textAlign: 'center' }}>
                                <SmallText>
                                    <Link to="/terms-of-use/">Terms of Use</Link> | <Link to="/privacy-policy/">Privacy Policy</Link> | © 2023 All Rights Reserved
                                </SmallText>
                            </Row>
                    </Column>
                </FooterWrapper>
            </Section>
    </footer>
    );
}

export default Footer;
