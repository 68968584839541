import styled from "styled-components";
import { isMobileDevice } from "../../constants/windowSize.tsx";

export const HeaderContainer = styled.div`
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(4, 18, 67, 0.5);
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    background-color: rgba(7, 53, 123, 1);
  }
`;

export const LogoAndButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  align-items: center;
  height: 7rem;
  min-height: 55px;
  max-height: 70px;
`;

export const Buttons = styled.div`
  display: flex;
  color: white;
  align-items: center;
  width: 450px;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

interface ButtonProps {
  isSelectedButton: boolean;
}

export const Button = styled.div`
  font-size: 15px;
  cursor: pointer;
  padding-left: 2%;
  padding-right: 2%;
  border: 2px solid rgba(0, 0, 0, 0);
  border-radius: 5px;

  :hover {
    ${({ isSelectedButton }: ButtonProps) => {
      if (!isSelectedButton) {
        return { color: "rgba(192, 199, 209)" };
      }
    }}
  }

  ${(props: ButtonProps) => {
    if (props.isSelectedButton) {
      return isMobileDevice
        ? { backgroundColor: "rgba(115, 170, 255, 0.33)" }
        : {
            color: "#72FFFF",
            border: "2px solid #72FFFF",
          };
    }
  }};

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    :hover {
      background-color: rgba(115, 170, 255, 0.33);
    }
  }
`;

export const OvioLogo = styled.img`
  margin-top: 10%;
  width: 6rem;
  min-width: 100px;
  @media (max-width: 768px) {
    width: 15rem;
  }
`;

export const MenuImage = styled.img`
  height: 6rem;
  width: 6rem;
  max-height: 30px;
  max-width: 30px;
  @media (hover: active) {
    opacity: 0.5;
  }
`;
