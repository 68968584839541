import {
  WalletContainer,
  StepCardImg,
  Title,
  StepsCards,
  TitleContainer,
  SubTitle,
  Text,
  WalletStepContainer,
  SdkDocLink,
} from "./styles.tsx";
import { WALLET_STEPS } from "./constants.tsx";

const Wallet = () => {
  return (
    <WalletContainer>
      <TitleContainer>
        <Title src={require(`assets/images/Wallet/title.png`)}></Title>
        <SubTitle>USER PERSPECTIVE</SubTitle>
      </TitleContainer>
      <>
        {WALLET_STEPS.map(({ title, srcs }, index) => {
          return (
            <WalletStepContainer>
              <Text shouldHaveMarginBottom={true}>{title}</Text>
              <StepsCards>
                {srcs.map((src) => {
                  return (
                    <StepCardImg src={require(`assets/images/Wallet/${src}`)} />
                  );
                })}
              </StepsCards>
            </WalletStepContainer>
          );
        })}
      </>

      <SdkDocLink
        href="https://www.npmjs.com/package/ovio-gg-web3"
        target="_blank"
        rel="noreferrer">
        <span>SDK DOCUMENTATION PAGE</span>
      </SdkDocLink>
    </WalletContainer>
  );
};

export default Wallet;
