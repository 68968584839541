import styled from "styled-components";
import aboutBackground from "assets/images/About/aboutBackground.png";
import { media } from "../../utils/styledComponent.ts";

export const AboutContainer = styled.div`
  width: 100%;
  background: url(${aboutBackground});
  color: white;
  background-size: 100% 100%;
  display: flex;
  padding-top: 50px;
  flex-direction: column;
  align-items: center;
`;

export const TextContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  width: 80%;

  ${media.ipadMini`
    height: 88rem;
    width: 85%;
    flex: 1;
    margin-bottom: -12%;
  `}
`;

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 5%;

  ${media.ipadMini`
    width: 100%;
    margin-top: 15%;
  `}
`;

export const TitleImage = styled.img`
  width: 14rem;
  min-width: 120px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${media.ipadMini`
    width: 44rem;
    margin-bottom: 4rem;
  `}
`;

export const TeamTitle = styled.div`
  letter-spacing: 5px;
  font-size: 18px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 18px;

  ${media.ipadMini`
    font-size: 15px;
  `}
`;

export const DescriptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin-bottom: 8%;

  ${media.ipadMini`
    width: 100%;
    margin-bottom: 16%;
  `}
`;