import { InputContainer } from "./styles.tsx";
import { useState } from "react";

interface Props {
  placeholder: string;
  customStayle?: any;
  onChange?: () => {};
  name?: string;
  value: string;
  isInvalidField?: boolean;
}

export const Input = ({
  placeholder,
  customStayle,
  onChange,
  name,
  value,
  isInvalidField,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <InputContainer
      customStayle={customStayle}
      type="text"
      placeholder={placeholder}
      onFocus={handleInputFocus}
      isFocused={isFocused}
      onBlur={handleInputBlur}
      onChange={onChange}
      name={name}
      value={value}
      isInvalidField={isInvalidField}
    />
  );
};

export default Input;
