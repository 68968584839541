export const text = {
  pages: {
    home: {
      sections: {
        ourProducts: {
          uaEngine: {
            title: "1. UA ENGINE",
            paragraph1:
              "OviO users complete game missions  and earn rewards, resulting in a remarkable +70% conversion to monetization, boosting revenue and dropping media costs by over 60%",
            paragraph2:
              "Partnering with us takes 30 seconds (for slow workers), doesn't require tech integration and can put the common UA manager on the highest ROAS perform of any given month.",
            paragraph3:
              "Our AI driven user flow engine is the perfect game to players match maker, generating revenue and reducing game developers costs",
            paragraphForMobile:
              "OviO users complete missions, earn rewards with +70% conversion, cutting media costs by 60%. Partner in 30s, no tech integration. Highest ROAS for UA managers. AI-driven user flow engine boosts revenue, reduces costs.",
          },
          wallet: {
            title: "2. WALLET",
            paragraph1:
              "Introducing the groundbreaking OviO Wallet - driving traditional players to Web3 games at an unprecedented scale.",
            paragraph2:
              "Seamlessly integrated into gameplay, our wallet boasts a remarkable 90% conversion rate of traditional gamers to wallet owners.",
            paragraphForMobile:
              "Introducing the groundbreaking OviO Introducing OviO Wallet - driving traditional players to Web3 games at an unprecedented scale. Seamlessly integrated, it achieves a remarkable 90% conversion rate of gamers to wallet owners.",
          },
          crossGameCurrency: {
            title: "3. THE GOLD STANDARD OF CROSS GAME CURRENCY",
            paragraph1:
              "The final step in a new game era, where gameplay and game assets hold real money value external to games.",
            paragraph2:
              "With OviO users  can convert game assets to other games or cash them out as gift cards or crypto tokens.",
            paragraphForMobile:
              "In the new game era, OviO lets users convert game assets to other games, cash them out as gift cards or crypto tokens, holding real money value external to games.",
          },
        },
      },
    },
    aboutUs: {
      ourVision: {
        paragraph1:
          "OviO's vision is to revolutionize the gaming industry by empowering players to convert in-game currency to real-life dollar-worth currency, unlocking the untapped financial assets market within gaming.",
      },
      ourMission: {
        paragraph1:
          "Our mission is to become the go-to destination for players and game developers, empowering players to enhance their gaming experience and increase user value for game developers.",
      },
    },
  },
};
