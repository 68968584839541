import { createGlobalStyle } from "styled-components";

import NunitoSans_400 from "./NunitoSans-Regular.ttf";
import NunitoSans_700 from "./NunitoSans-Bold.ttf";
import NunitoSans_800 from "./NunitoSans-ExtraBold.ttf";

import Poppins_400 from "./Poppins-Regular.ttf";
import Poppins_600 from "./Poppins-SemiBold.ttf";
import Poppins_700 from "./Poppins-Bold.ttf";
import Poppins_800 from "./Poppins-ExtraBold.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: "NunitoSans_400";
        src: local("NunitoSans_400"),
        url(${NunitoSans_400}) format("truetype");
        font-weight: 400;
    }
  
    @font-face {
        font-family: "NunitoSans_700";
        src: local("NunitoSans_700"),
        url(${NunitoSans_700}) format("truetype");
        font-weight: 700;
    }
  
    @font-face {
      font-family: "NunitoSans_800";
      src: local("NunitoSans_800"),
        url(${NunitoSans_800}) format("truetype");
      font-weight: 800;
    }
        @font-face {
        font-family: "Poppins";
        src: local("Poppins_400"), url(${Poppins_400}) format("truetype");
        font-weight: 400;
    }

    @font-face {
        font-family: "Poppins";
        src: local("Poppins_600"), url(${Poppins_600}) format("truetype");
        font-weight: 600;
    }

    @font-face {
        font-family: "Poppins";
        src: local("Poppins_700"), url(${Poppins_700}) format("truetype");
        font-weight: 700;
    }
  
    @font-face {
      font-family: "Poppins";
      src: local("Poppins_800"), url(${Poppins_800}) format("truetype");
      font-weight: 800;
    }

    body {
        font-family: Poppins, sans-serif;
    }
`;
