import {
  AboutContainer,
  TitleImage,
  TextContainer,
  Text,
  TeamContainer,
  TeamTitle,
  DescriptionsContainer,
} from "./styles.tsx";
import { text } from "localization/en.tsx";
import { DESCRIPTIONS } from "./constants.tsx";
import Team from "./Team/Team.tsx";

const About = () => {
  return (
    <AboutContainer>
      <TextContainer>
        {DESCRIPTIONS.map(({ id, src, paragraphs }) => {
          return (
            <DescriptionsContainer key={id}>
              <TitleImage src={require(`assets/images/About/${src}`)} />
              {paragraphs.map((paragraph) => {
                return <Text>{text.pages.aboutUs[id][paragraph]}</Text>;
              })}
            </DescriptionsContainer>
          );
        })}
      </TextContainer>
      <TeamContainer>
        <TeamTitle>OUR TEAM</TeamTitle>
        <Team />
      </TeamContainer>
    </AboutContainer>
  );
};

export default About;
