export const TEAM_PERSONS = [
  {
    name: "Nir H.",
    job: "CEO",
    src: "nir.png",
    linkedin: "nir-hauer",
  },
  {
    name: "Yogev R.",
    job: "COO",
    src: "yogev.png",
    linkedin: "yogev-regev",
  },
  {
    name: "Orel S.",
    job: "CSO",
    src: "orel.png",
    linkedin: "or-el-sror-b0713b125",
  },
  {
    name: "Niv N.",
    job: "CTO",
    src: "niv.png",
    linkedin: "niv-nachmani",
  },
  {
    name: "Tomer Z.",
    job: "VP PRODUCT",
    src: "tomer.png",
    linkedin: "tomerzeevi",
  },
  {
    name: "Shatzi A.",
    job: "Head of UX",
    src: "shatzi.png",
    linkedin: "shatzihe",
  },
  {
    name: "Natalia E.",
    job: "Sr. Product Designer",
    src: "natalia.png",
    linkedin: "nataliaelg",
  },
  {
    name: "Noa G.",
    job: "Sr. Software Engineer",
    src: "noa.png",
    linkedin: "noagoren1",
  },
  {
    name: "Liav R.",
    job: "Software Engineer",
    src: "liav.png",
    linkedin: "liav-regev",
  },
  {
    name: "Liron P.",
    job: "Software Engineer",
    src: "liron.png",
    linkedin: "liron-pariente-620787172",
  },
];
