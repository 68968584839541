import { Auth, Hub } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";

const AccountContext = createContext();

const Account = (props) => {   
    
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        Hub.listen('auth', async ({ payload: { event, data } }) => {
          switch (event) {
            case 'signIn':
                setCurrentUser(data);
                break;
            case 'signOut':
                setCurrentUser(null);
                break;
            default:
                break;
          }
        });
      }, []);


    const checkIfAuthenticated = async () => {        
        await new Promise((resolve, reject) => {
            let currentUser = null;
            try {
                currentUser = Auth.currentAuthenticatedUser();
                resolve(currentUser);
            } catch (error) {                
                reject(error);
            }
        })
    }

    return (
        <AccountContext.Provider value={{ checkIfAuthenticated, currentUser }}>
            {props.children}
        </AccountContext.Provider>
    )
}

export { Account, AccountContext };