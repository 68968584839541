import styled from "styled-components";

interface InputContainerProps {
  isFocused: boolean;
  customStayle?: string;
  isInvalidField?: boolean;
}

export const InputContainer = styled.input`
  width: 15rem;
  height: 3rem;
  border: 3.5px solid;
  padding: 10px;
  font-size: 1rem;
  color: ${({ isFocused }: InputContainerProps) => {
    return isFocused ? "rgba(114, 255, 255)" : "rgba(0, 194, 255, 1)";
  }};
  border-color: ${({ isFocused }: InputContainerProps) => {
    return isFocused ? "rgba(114, 255, 255)" : "rgba(0, 194, 255, 1)";
  }};
  border-radius: 5px;
  &:focus {
    outline: none;
  }
  background-color: rgba(0, 0, 0, 0);
  ${({ customStayle }) => {
    if (customStayle) {
      return customStayle;
    }
  }}
  &::placeholder {
    color: rgba(72, 162, 191);
  }
  &:hover {
    ${({ isInvalidField }: InputContainerProps) => {
      if (!isInvalidField) {
        return { borderColor: "rgba(114, 255, 255)" };
      }
    }}
  }
  @media (max-width: 768px) {
    width: 50rem;
    height: 8rem;
    font-size: 3.5rem;
    ${({ customStayle }) => {
      if (customStayle) {
        return customStayle;
      }
    }}
  }
`;
