export const TRUSTED_PARTNERS_FIRST_ROW = [
  { name: "playtika", src: "playtika.png" },
  { name: "superPlay", src: "superPlay.png" },
  { name: "scopely", src: "scopely.png" },
  { name: "sciPlay", src: "sciPlay.png" },
  { name: "baba", src: "baba.png" },
  { name: "innplayLabs", src: "innplayLabs.png" },
  { name: "deemedya", src: "deemedya.png" },
  { name: "astromust", src: "astromust.png" },
  { name: "chrono", src: "chrono.png" },
  { name: "homieWars", src: "homieWars.png" },
  { name: "tappsGames", src: "tappsGames.png" },
  { name: "etermax", src: "etermax.png" },
  { name: "brainGames", src: "brainGames.png" },
  { name: "everyBuddy", src: "everyBuddy.png" },
  { name: "appQuantum", src: "appQuantum.png" },
];

export const INTEGRATED_PARTNERS = [
  { name: "adjust", src: "adjust.png" },
  { name: "appFluer", src: "appFluer.png" },
  { name: "singular", src: "singular.png" },
];

export const PARTNERS_DATA = [
  {
    id: "trustedBy",
    title: "TRUSTED BY",
    partnersList: TRUSTED_PARTNERS_FIRST_ROW,
  },
  {
    id: "integratedPartners",
    title: "INTEGRATED PARTNERS",
    partnersList: INTEGRATED_PARTNERS,
  },
];
