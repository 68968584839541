import styled from "styled-components";
import introductionBackground from "assets/images/Home/Introduction/introductionBackground.png";
import introductionBackgroundForMobile from "assets/images/Home/Introduction/introductionBackgroundForMobile.png";
import { isMobileDevice } from "../../../../constants/windowSize.tsx";

export const IntroductionContainer = styled.div`
  height: 65%;
  background-image: ${() => {
    return isMobileDevice
      ? `url(${introductionBackgroundForMobile})`
      : `url(${introductionBackground})`;
  }};
  padding-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 110rem;
    padding-top: 4rem;
  }
  @media (max-width: 1000px) {
    justify-content: space-evenly;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  justify-content: center;
  width: 25rem;
  height: 100%;

  @media (max-width: 768px) {
    height: none;
  }
`;

export const JustGotBetterImage = styled.img`
  width: 25rem;
  @media (max-width: 768px) {
    width: 70rem;
  }
`;

export const StoreButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const StoreButton = styled.a`
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-left: 1.5%;
  margin-right: 1.5%;
`;

export const StoreImage = styled.img`
  width: 8.8956875rem;
  height: 2.625rem;
  @media (max-width: 768px) {
    margin-top: 5rem;
    margin-bottom: -12rem;
    width: 30rem;
    height: 8.7rem;
  }
`;

export const DevicesImage = styled.img`
  width: 25rem;
  @media (max-width: 768px) {
    width: 70%;
  }

  ${() => {
    if (!isMobileDevice) {
      return { minWidth: "350px" };
    }
  }};
`;
