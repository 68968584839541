import styled from "styled-components";

export const CpeFlowContainer = styled.div`
  width: 100%;
  background: url(${require("assets/images/CpeFlow/cpeFlowBackground.png")});
  color: white;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
  position: relative;
  @media (min-width: 768px) {
    height: 100%;
  }
`;

export const Text = styled.p`
  letter-spacing: 5px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 85rem;
  margin-top: 3rem;
  max-width: 1450px;

  @media (max-width: 800px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    padding-bottom: 4rem;
    width: 100%;
  }
`;

export const IntegratedPartnersBar = styled.div`
  width: 100vw;
  height: 15%;
  background: url(${require("assets/images/CpeFlow/integratedPartnersBar.png")});
  position: absolute;
  background-size: 100% 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    height: 8%;
  }
`;

export const IntegratedPartnersImg = styled.img`
  width: 230.904px;
  height: 64.182px;
  align-self: center;
  @media (max-width: 768px) {
    height: 8.824025rem;
    width: 52.9155rem;
  }
`;

export const Title = styled.img`
  width: 10rem;
  min-width: 150px;
`;

export const Card = styled.img`
  width: 25.2384rem;
  height: auto;

  @media (max-width: 768px) {
    width: 65.47168rem;
    height: 52.47168rem;
    margin-bottom: 8rem;
    max-width: none;
    max-height: none;
  }
`;
