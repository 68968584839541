import {
  ContactUsContainer,
  TitleImage,
  OvioLogo,
  FieldsContainer,
  InputContainer,
  ErrorText,
  MessageText,
  MessageTextContainer,
} from "./style.tsx";
import title from "assets/images/Home/ContactUs/title.png";
import Input from "components/Input/Input.tsx";
import { FIELDS, OPTIONAL_FIELDS_INVALID } from "./constants.tsx";
import TextButton from "components/Buttons/TextButton.tsx";
import { useState } from "react";
import axios from "axios";
import { isMobileDevice } from "constants/windowSize.tsx";
import { Fields, CheckFieldsInvalid } from "./types.tsx";
import {
  FooterContainer,
  FooterLink,
  FooterText,
} from "pages/TemporaryPage/styles.ts";

const ContactUs = () => {
  const { NAME, EMAIL, PHONE, MESSAGE } = Fields;
  const [isSubmitCliked, setIsSubmitCliked] = useState(false);
  const [shouldShowSuccessMessage, setShouldShowSuccessMessage] =
    useState(false);
  const [formFields, setFormFields] = useState({
    [NAME]: "",
    [EMAIL]: "",
    [PHONE]: "",
    [MESSAGE]: "",
  });

  const [invalidFields, setInvalidFields] = useState({
    [NAME]: null,
    [EMAIL]: null,
  });

  const checkFieldValue = (name: string, value: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    switch (name) {
      case NAME:
        return !value.length;
      case EMAIL:
        return !value.length || !emailRegex.test(value);
      default:
        return false;
    }
  };

  const checkFieldsInvalid = (field?: CheckFieldsInvalid) => {
    if (field) {
      const { name, value } = field;
      if (!OPTIONAL_FIELDS_INVALID.includes(name)) return;
      let isInvalidField = checkFieldValue(name, value);
      setInvalidFields({ ...invalidFields, [name]: isInvalidField });
    } else {
      let isFieldsInvalid = false;
      let updateInvalidFields = { ...invalidFields };
      OPTIONAL_FIELDS_INVALID.forEach((field) => {
        const checkFieldValueResult = checkFieldValue(field, formFields[field]);
        updateInvalidFields[field] = checkFieldValueResult;
        if (checkFieldValueResult) {
          isFieldsInvalid = true;
        }
      });
      setInvalidFields(updateInvalidFields);
      return { isFieldsInvalid };
    }
  };

  const onChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormFields({ ...formFields, [name]: value });
    isSubmitCliked && checkFieldsInvalid({ name, value });
  };

  const renderFields = () => {
    return (
      <FieldsContainer>
        {FIELDS.map(({ placeholder, id }) => {
          const isInvalidField = invalidFields[id];
          return (
            <InputContainer key={id}>
              <Input
                isInvalidField={isInvalidField}
                customStayle={{
                  borderColor: isInvalidField
                    ? "rgba(255, 55, 127, 1)"
                    : undefined,
                  width:
                    id === MESSAGE && !isMobileDevice ? "48rem" : undefined,
                  height:
                    id === MESSAGE
                      ? isMobileDevice
                        ? "20rem"
                        : "7rem"
                      : undefined,
                }}
                placeholder={placeholder}
                onChange={(e) => onChange(e)}
                name={id}
                value={formFields[id]}
              />
              {isInvalidField && <ErrorText>*Mandatory field</ErrorText>}
            </InputContainer>
          );
        })}
      </FieldsContainer>
    );
  };

  const onSubmit = () => {
    setIsSubmitCliked(true);
    if (checkFieldsInvalid()?.isFieldsInvalid) return;
    const apiUrl = `${process.env.REACT_APP_PROD_API}/contactus/website`;
    const body = {
      Topic: `Message from ${formFields.name}`,
      Message: `Email: ${formFields.email}<br/> Phone: ${formFields.phone} <br/><br/> ${formFields.message}`,
    };
    axios.post(apiUrl, body);
    setShouldShowSuccessMessage(true);
  };

  return (
    <ContactUsContainer id="contactUs">
      <TitleImage src={title} />
      {shouldShowSuccessMessage ? (
        <MessageTextContainer>
          <MessageText>Thank you for contacting us,</MessageText>
          <MessageText>we’ll be in touch very soon.</MessageText>
        </MessageTextContainer>
      ) : (
        <>
          {renderFields()}
          <TextButton text={"Submit"} onClick={onSubmit}></TextButton>
        </>
      )}
      <OvioLogo src={require("assets/images/ovioLogo.png")} />
      <FooterContainer>
        <FooterLink href="/terms-of-use" target="_blank">
          Terms of Use
        </FooterLink>
        |
        <FooterLink href="/privacy-policy" target="_blank">
          Privacy Policy
        </FooterLink>
        |<FooterText>© 2023 OviO All Rights Reserved</FooterText>
      </FooterContainer>
    </ContactUsContainer>
  );
};

export default ContactUs;
