import styled from "styled-components";

export const TermsStyle = styled.div`
  background-color: #171d24;
  div {
    max-width: 1024px;
    margin: 0 auto;
    > ol > li {
      font-family: "NunitoSans_800";
      margin: 20px 0;
    }
  }

  padding: 20px;
  color: white;

  h1 {
    text-align: center;
    font-size: 48px;
    font-family: "NunitoSans_800";
  }

  h2 {
    text-align: center;
    text-decoration: underline;
    font-size: 18px;
    font-family: "NunitoSans_700";
  }

  p,
  li {
    font-size: 16px;
    font-family: "NunitoSans_400";
    line-height: 30px;
  }

  a {
    color: white;
    text-decoration: underline;
  }
`;
