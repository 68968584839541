import ReactGA from "react-ga";

const useAnalyticsEventTracker = (category = "") => {
  const actions = {
    getStarted: "Clicked Get Started",
    googlePlay: "Clicked Google Play",
    appStore: "Clicked App Store",
    discord: "Link to Discord",
    linkedin: "Link to LinkedIn",
    twitter: "Link to Twitter",
    facebook: "Link to Facebook",
    instagram: "Link to Instagram",
    joinTeam: "Clicked Join Team", // categories: About + The Team
    applyJob: "Apply Job",
    joinOvio: "Clicked Join Ovio",
    signInSuccess: "Signed In",
    signInFailure: "Sign In Failure",
    signOut: "Signed out", // categories: Login / Header
    signOutNotAuth: "Auto Signed out - not auth",
    contactSuccess: "Sent Contact Form",
    contactFailure: "Contact Form Failure",
    login: "Clicked Login",
    forgotPassword: "Clicked Forgot Password",
    forgotPasswordSendCodeSuccess: "Sent Forgot Password Code",
    forgotPasswordSendCodeFailure: "Send Forgot Password Code Failure",
    forgotPasswordSuccess: "Reset Password",
    forgotPasswordFailure: "Reset Password Failure",
  };

  const gaEventTracker = ({
    action = "",
    label = "",
  }: {
    action: string;
    label: string;
  }) => {
    ReactGA.event({ category, action, label });
  };
  return { gaEventTracker, actions };
};

export default useAnalyticsEventTracker;
