import { Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./pages/Home/Home.tsx";
import Header from "components/Header/Header.tsx";
import Terms from "./pages/terms/Terms.tsx";
import Privacy from "pages/terms/Privacy.tsx";
import About from "pages/about/About.tsx";
import CpeFlow from "pages/CpeFlow/CpeFlow.tsx";
import Wallet from "pages/Wallet/Wallet.tsx";
import ScrollToTop from "components/ScrollToTop/ScrollToTop.tsx";

function App() {
  const location = useLocation();
  const shouldShowHeader = !(location.pathname.includes('/terms-of-use') || location.pathname.includes("/privacy-policy"));

  ReactGA.initialize("UA-211333125-1");

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <div style={{ height: "100vh" }}>
      {shouldShowHeader && <Header />}
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms-of-use" element={<Terms />} />
          <Route path="/terms-of-use/:type" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/cpeFlow" element={<CpeFlow />} />
          <Route path="/wallet" element={<Wallet />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
