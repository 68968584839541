import styled from "styled-components";
import ourProductsBackground from "assets/images/Home/OurProducts/ourProductsBackground.png";
import leftSideCard from "assets/images/Home/OurProducts/leftSideCard.png";
import rightSideCard from "assets/images/Home/OurProducts/rightSideCard.png";
import cardForMobile from "assets/images/Home/OurProducts/cardForMobile.png";
import { windowWidth } from "constants/windowSize.tsx";
import { isMobileDevice } from "constants/windowSize.tsx";

export const OurProductsContainer = styled.div`
  width: 100%;
  background-image: url(${ourProductsBackground});
  background-size: cover;
  color: white;
  background-size: 100% 100%;
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3%;
  @media (max-width: 768px) {
    padding-bottom: 12rem;
    padding-top: 8rem;
  }
`;

export const CoinImage = styled.img`
  width: 10%;
  background-size: 100% 100%;
`;

export const TitleImage = styled.img`
  width: 20%;
  background-size: 100% 100%;
  margin-bottom: 2%;
  min-width: 200px;
  @media (max-width: 768px) {
    width: 50rem;
    min-width: 0;
    margin-bottom: 3rem;
  }
`;

interface CardProps {
  isLeftSideCard: boolean;
}

export const DeviceImage = styled.img`
  width: 38%;
  background-size: 100% 100%;
  margin-bottom: 2%;
  position: absolute;
  right: -22%;
  top: 5%;
  ${({ isLeftSideCard }: CardProps) => {
    if (!isLeftSideCard && !isMobileDevice) {
      return { left: "-20%" };
    }
  }}
  @media (max-width: 768px) {
    width: 40%;
    right: 0rem;
    top: 1rem;
  }
`;

export const Card = styled.div`
  padding-top: 2%;
  text-align: center;
  margin-left: ${({ isLeftSideCard }: CardProps) => {
    return isLeftSideCard ? "-20%" : "20%";
  }};
  width: ${windowWidth / 2.5}px;
  height: ${windowWidth / 3.7}px;
  background-image: ${({ isLeftSideCard }: CardProps) => {
    return `url(${isLeftSideCard ? leftSideCard : rightSideCard})`;
  }};
  min-width: 400px;
  min-height: 300px;
  background-size: cover;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  position: relative;
  
  @media (max-width: 768px) {
    margin-top: 6rem;
    padding-top: 5%;
    margin-left: 0;
    width: 96%;
    height: ${windowWidth * 0.7}px;
    text-align: start;
    background-image: url(${cardForMobile});
    justify-content: flex-start;
    padding: 5rem;
    min-width: 100px;
    min-height: 200px;
  }
`;

export const CardTextAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const CardTextContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
    width: 65%;
  }
`;

export const CardTitle = styled.p`
  margin-bottom: 3%;
  width: 100%;
  font-size: 1.5rem;
  @media (max-width: 768px) {
    font-size: 3rem;
    width: 90%;
  }
`;

export const ParagraphText = styled.p`
  margin-bottom: 3%;
  font-size: 0.92rem;
  /* min-width: 550px; */ /* font-size: 16px; */
  @media (max-width: 768px) {
    font-size: 2.25rem;
  }
`;

interface ITextButtonContainerProps {
  textLength: number;
}

export const TextButtonContainer = styled.div`
  ${({ textLength }: ITextButtonContainerProps) => {
    return {width: `${textLength > 10 ? '12rem' : '8rem'}`};
  }};

  @media (max-width: 768px) {
    ${({ textLength }: ITextButtonContainerProps) => {
      return {width: `${textLength > 10 ? '40rem' : '20rem'}`};
    }};
  }
`;
