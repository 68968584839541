import styled from "styled-components";

interface ExternalButtonProps {
  isDisable?: boolean;
  isFullWidth?: boolean;
}

export const ExternalButton = styled.div`
  width: 8rem;
  min-width: 100px;
  background-color: rgba(18, 74, 98);
  padding-bottom: 2.5px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1%;
  ${({ isDisable }: ExternalButtonProps) => {
    if (isDisable) {
      return { opacity: 0.5 };
    }
  }}
  ${({ isFullWidth }: ExternalButtonProps) => {
    if (isFullWidth) {
      return { width: '100%' };
    }
  }}

  @media (max-width: 768px) {
    width: 30%;
    margin-top: 20px;
    margin-bottom: 20px;
    ${({ isFullWidth }: ExternalButtonProps) => {
      if (isFullWidth) {
        return { width: '100%' };
      }
    }}
  }
`;

export const InternalButton = styled.div`
  background-color: rgba(18, 230, 230, 1);
  border-radius: 10px;
  height: 2.8rem;
  min-height: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    height: 35px;
  }
  &:hover {
    background-color: rgba(14, 197, 203);
  }
  &: active {
    background: linear-gradient(0deg, #2C96B8 0%, rgba(255, 255, 255, 0.00) 0.01%, rgba(84, 170, 197, 0.81) 100%), #0ECBCB; 
  }
`;

export const Text = styled.p`
  font-weight: 900;
  -webkit-text-stroke: 0.8px rgba(18, 74, 98);
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
