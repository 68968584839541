import Footer from "pages/landing/footer/Footer.tsx";
import { Link } from "react-router-dom";
import { TermsStyle } from "./style";
import { useParams } from "react-router-dom";

function Terms() {
  const { type } = useParams();
  const hideTitle = type === 'mobile-application';

  return (
    <div className="aboutBG">
      <TermsStyle>
        <div>
          {!hideTitle && <h1>Terms of use</h1>}
          {!hideTitle && <h2>OviO Application Terms of Use</h2>}
          <ol>
            <li>General</li>
            <ul>
              <li>
                These Terms of Use (the “Terms”) are a legally binding agreement
                between OviO Gaming (“OviO”), and the User with respect to the
                App (both as defined below). Please carefully read the following
                Terms before using the App so that you are fully aware of your
                legal rights and obligations with respect thereto.
              </li>
              <li>
                These Terms govern your access to and use of the App, the
                Content, the Services (as such terms are defined below) and the
                features contained therein.
              </li>
              <li>
                The term “us”, “we,” or “our”, refers to OviO, the owner of the
                App. The term “you” refers to any User of the App (as defined
                below).
              </li>
              <li>
                The term “including” is not intended to be exclusive and means
                “including without limitation”.
              </li>
            </ul>

            <li>Accepting the Terms of Use</li>
            <p>
              These Terms are effective as of the date you first install, access
              or use the App. Such installation, access or use of the App
              indicates your acceptance of the terms and conditions contained
              herein and you agree to be bound by these Terms with respect to
              your access and use of the App.
            </p>

            <li>Definitions</li>
            <p>For the purpose of these Terms:</p>
            <ul>
              <li>
                “App” means the mobile application offered by OviO, in its
                latest version, and all documentation, Content, and Services
                that will be made available by us to you.
              </li>
              <li>“Account” means an account opened by the User on the App.</li>
              <li>
                “Content” means any input or data displayed or otherwise
                available on the App, whether published and/or uploaded and/or
                posted by us, by you or by any other person or entity.
              </li>
              <li>
                “External Sites” means any third-party websites, plug-ins or
                applications, including Games.
              </li>
              <li>
                “Game Coins” means virtual currency that is purchased (with OviO
                Credit) through the App and may be used in a specific Game.
              </li>
              <li>
                “Game” means any game listed in the App, as updated from time to
                time.
              </li>
              <li>
                “OviO Credit” means points purchased and/or exchanged to or from
                Game Coins via the App.
              </li>
              <li>
                “Services” means all services provided through the App,
                including (among others) the ability to purchase OviO Credit,
                exchange OviO Credit for Game Coins, exchange Game Coins into
                OviO Credit, transfer OviO Credit to another User, exchange OviO
                Credit to gifts and/or rewards, and to send notifications
                relating to the App.
              </li>
              <li>
                “User” means any person or entity installing, accessing or using
                the App.
              </li>
            </ul>

            <li>Limited License</li>
            <p>
              OviO hereby grants you a limited, revocable, non-exclusive,
              non-transferable, non-sublicensable license to install, access and
              use the App and to access and use the Services by yourself, all
              under the condition that you comply with all of your obligations
              under these Terms. We grant you no other rights, implied or
              otherwise.
            </p>

            <li>The App</li>
            <ul>
              <li>
                You expressly acknowledge and agree that any access to, or use
                of, the App, and any consequences thereof, are at your sole
                risk, responsibility and liability.
              </li>
              <li>
                In order to access the App and the Services, you will be
                required to open an Account by providing information as part of
                the registration process to the App or by registering using your
                Google, Facebook or Apple account. You are responsible for
                providing up-to-date, accurate information, maintaining the
                confidentiality and security of the Account and credentials, as
                well as for all activities that occur in such Account.
              </li>
              <li>
                The form and/or features of the App or Services may change from
                time to time without prior notice.
              </li>
              <li>
                In addition, we may, at our sole discretion and at any time: (a)
                stop (permanently or temporarily) operating the App, providing
                the Services, completely or with respect to certain Games; (b)
                stop displaying any of the Content; and/or (c) remove any Game
                from the App. We may terminate your access to the App and the
                Services, at our sole discretion at any time, without notice and
                without liability to us.
              </li>
              <li>
                We also retain the right to restrict the use of, or access to,
                the App and the Services by you or any other Users, at our sole
                discretion at any time without notice and without liability to
                us.
              </li>
            </ul>

            <li>The Content</li>
            <ul>
              <li>
                You agree and acknowledge that any use or reliance on any
                Content presented on the App is at your own discretion and does
                not impose any responsibility or liability on OviO.
              </li>
              <li>
                The Content does not constitute a recommendation, opinion,
                advice and/or offer to purchase an asset, product or service and
                any reliance upon any Content shall be at the User’s sole risk.
                OviO shall bear no responsibility for the User’s reliance upon
                any Content and/or for the degree to which the Content accords
                with the User’s use and/or needs. OviO disclaims all
                responsibility for any Content and does not guarantee its
                accuracy.
              </li>
              <li>
                OviO takes no responsibility and assumes no liability for any
                Content. OviO is not obligated to review or monitor, and does
                not approve, endorse, or make any representations or warranties
                with respect to Content.
              </li>
              <li>
                OviO does not warrant against deletion of any Content, Game
                Coins and/or OviO Credit, or the failure to recreate, re-upload
                or re-post any Content to or on the App, whether it was
                published and/or posted by OviO or by any other person or
                entity.
              </li>
              <li>
                OviO does not guarantee and/or warrant that the Content is true,
                accurate or up-to-date.
              </li>
              <li>
                Accordingly, OviO encourages you to be careful and cautious when
                using the Content and/or the Services. Any use of the Content
                and/or the Services is at your own risk.
              </li>
              <li>
                OviO will have access to, and may use, the Content in accordance
                with these Terms.
              </li>
            </ul>

            <li>Game Coins and OviO Credit</li>
            <ul>
              <li>
                As part of our Services, you may be able to purchase OviO Credit
                using real money. Such purchases are considered “in-app
                purchases” and can be made solely via Google Pay or Apple Pay
                (as applicable) as a one-time payment or as a recurring payment
                every month, subject to the terms of use of such services. All
                purchases are final and may not be refunded.
              </li>
              <li>
                You may also exchange OviO Credit for Game Coins and Game Coins
                for OviO Credit, however, you will not be able to exchange any
                virtual currency gained and/or purchased through your use of the
                Games or other External Sites. Once a Game is no longer
                available via the App, you: (a) will not be able to exchange
                Game Coins of that Game for OviO Credit; (b) will not be able to
                exchange OviO Credit into such Game Coins; and (c) may not be
                able to use your Game Coins in such Game.
              </li>
              <li>
                To use Game Coins that you purchased through the App, the Game
                to which such Game Coins relate must be installed on the same
                mobile device through which you use the App.
              </li>
              <li>
                You acknowledge that OviO may, from time to time, at OviO’s sole
                discretion, change the price for purchasing OviO Credit, as well
                as the exchange rates for converting OviO Credit into Game Coins
                and vice versa.
              </li>
              <li>
                You expressly acknowledge and agree that all purchases and/or
                exchanges, as listed in this Section ‎7, are at your sole risk,
                responsibility and liability. OviO does not guarantee and does
                not make any representation that you will be able to use
                purchased Game Coins in a specific Game. For further
                information, please refer to the Section titled “External Sites”
                below.
              </li>
            </ul>

            <li>Exchange of OviO Credit into gift or rewards</li>
            <ul>
              <li>
                From time to time, OviO may, at its sole discretion, allow any
                or all of its users, to exchange OviO Credit into certain gifts
                and/or rewards (such as Amazon gift cards), subject to terms as
                may be determined by OviO from time to time.
              </li>
              <li>
                Any such exchange of OviO Credit into gifts/rewards is final and
                you will not be able to exchange the gift and/or the reward back
                into OviO Credit or receive any refund or monetary compensation.
              </li>
              <li>
                You acknowledge that OviO may, from time to time and at any
                time, at OviO’s sole discretion, change the exchange rates for
                converting OviO Credit into gifts and/or rewards, the amounts
                included in the gift cards (if applicable), conditions for
                eligibility, the number of gifts/rewards offered and/or the
                offering of any gifts/rewards whatsoever.
              </li>
              <li>
                You expressly acknowledge and agree that all such exchanges and
                the use or utilization of any such gift or reward, as listed in
                this Section ‎8, are at your sole risk, responsibility and
                liability. OviO does not guarantee and does not make any
                representation that you will be able to use or utilize such
                gift/reward, which shall be subject to the terms and conditions
                of their issuer.
              </li>
            </ul>

            <li>Transfers to other Users</li>
            <ul>
              <li>
                As part of our Service, you may also be able to transfer OviO
                Credit from your balance to another User and/or receive OviO
                Credit from another User (“User Transfers”).
              </li>
              <li>
                User Transfers are irreversible and cannot be canceled or
                refunded. Prior to consummating a User Transfer, we encourage
                you to carefully check and verify the identity of the User to
                which you wish to transfer OviO Credit and the amount of OviO
                Credit you wish to transfer.
              </li>
              <li>
                User Transfers are made at your own risk and discretion, without
                imposing any responsibility or liability on OviO.
              </li>
              <li>
                User Transactions may not be used for any illegal activity or
                for activities that violate any applicable law or regulation,
                including but not limited to any activity that may facilitate
                money laundering, fraud, breach of sanctions or funding of
                terror.
              </li>
            </ul>

            <li>External Sites</li>
            <ul>
              <li>The Services may enable access to External Sites.</li>
              <li>
                Use of an External Site is subject to the terms of use of such
                External Site. We are not liable or responsible for an External
                Site’s compliance with applicable laws. It is recommended that
                you review the applicable terms and policies of any External
                Site to which you navigate from our App.
              </li>
              <li>
                You understand that by using any External Site, you may
                encounter content that may be deemed offensive, indecent, or
                objectionable, and which may or may not be identified as having
                explicit language. You acknowledge and agree that we are not
                responsible for any content published on an External Site.
              </li>
              <li>
                We make no representation as to the terms of use of any External
                Site (including terms relating to the use of Game Coins in any
                Game) or that any External Site is appropriate or available for
                use in any particular location.
              </li>
            </ul>

            <li>Notifications and Promotional Offers</li>
            <ul>
              <li>
                By using the App, you agree to receive marketing communications
                from us in the App or via other means (e.g., email), unless you
                have opted out of receiving such marketing communications. You
                can change your contact preferences at any time by sending us an
                email with your request to the email that appears in the Contact
                Information section below. If you wish to stop receiving
                marketing communications from us, you may ask us to stop sending
                you marketing communications at any time by following the
                opt-out link on any marketing message sent to you or by
                contacting us.
              </li>
              <li>
                By using the App, you also agree that we can send notifications
                to your mobile device (push notifications) or email regarding
                your activity, or activity relating to you, in the App. At any
                time, you can manage your push notification preferences or
                deactivate these notifications by turning off the notification
                settings in the device settings of your mobile device. You may
                ask us to stop sending email notification to you at any time by
                contacting us.
              </li>
            </ul>

            <li>Problems with the App</li>
            <ul>
              <li>
                We do not guarantee that our App will be compatible with any
                device or software which you may use, or that the App or
                Services will be uninterrupted or error free.
              </li>
              <li>
                We shall not be liable, regardless of the cause or duration, for
                inability to use the App and/or the Services or any part thereof
                with respect to certain Games or at all, and/or for any errors,
                inaccuracies, omissions, or other defects in, the information
                contained in our App (including the reliance upon any such
                information), or for any delay or interruption in the
                transmission thereof to you, or for any related claims or
                losses.
              </li>
            </ul>

            <li>Restrictions on Content and Use of the App</li>
            <ul>
              <li>You may not access the App or Services if:</li>
              <ul>
                <li>
                  You are not at least 13 years old, or older, if otherwise
                  required by the laws of the country where you reside, to be
                  authorized to accept these Terms without the approval of a
                  legal guardian. If you are not 18 years old or older, your
                  parent or legal guardian must accept these Terms on your
                  behalf as a condition for your access to the App and the
                  Services. If you are a parent or legal guardian accepting the
                  terms on your child’s behalf, please note that you shall be
                  bound by these Terms and shall be liable for your child’s
                  activity in connection with the App and the Services.
                </li>
                <li>
                  You are a person who is either barred or otherwise legally
                  prohibited from receiving or using the App under the laws of
                  the country in which you are resident, or from which you use
                  or access the App; or
                </li>
                <li>
                  Such access or use are made in a manner which is inconsistent
                  with these Terms and all applicable laws, rules and
                  regulations.
                </li>
              </ul>
              <li>
                You may not do any of the following while accessing the App or
                using the Services:
              </li>
              <ul>
                <li>
                  use the App otherwise than in compliance with these Terms and
                  all applicable laws and regulations;
                </li>
                <li>facilitate or encourage any violation of these Terms;</li>
                <li>
                  except as otherwise provided in these Terms, copy, reproduce,
                  print, download or save a copy, republish, display, perform,
                  advertise, distribute, transmit, broadcast, decompile, reverse
                  engineer, disassemble, attempt to derive the source code of,
                  adapt, modify, create derivative works from, sell, rent,
                  lease, loan or otherwise make available or exploit in any form
                  or by any means all or any portion of the App or any Content,
                  for any purpose;
                </li>
                <li>scrape, or otherwise cache any Content;</li>
                <li>
                  remove or alter any patent numbers, copyright notices,
                  trademark notices or other proprietary notices or identifying
                  marks, symbols or legends included in the App;
                </li>
                <li>
                  use the App for any unlawful purpose or for promotion of
                  illegal activities;
                </li>
                <li>
                  use the App to abuse, harass, threaten, impersonate or
                  intimidate any person;
                </li>
                <li>
                  use the App for interfering with, or disrupting (or attempting
                  to do so), the access of any person, host or network,
                  including, without limitation, by uploading and/or sending
                  software viruses or any other computer code, files or programs
                  designed to interrupt, destroy or limit the functionality of
                  any computer software or hardware; overloading, flooding,
                  spamming, mail-bombing; or by scripting the creation of
                  Content in such a manner as to interfere with or create an
                  undue burden on the App or the Services;
                </li>
                <li>
                  do anything that could disable, overburden, or impair the
                  proper activity of the App or the Services;
                </li>
                <li>
                  do anything that could harm or damage the business activity of
                  OviO or its legitimate interest;
                </li>
                <li>
                  Interfere with or violate the right to privacy or collect or
                  store personal data about other Users in connection with the
                  prohibited conduct and activities set forth in this Section 9
                  above.
                </li>
              </ul>
              <li>
                You are solely responsible and liable for, and OviO has no
                responsibility to you or to any third party for, any breach of
                your obligations under these Terms and for the consequences
                (including any loss or damage which OviO may suffer) of any such
                breach.
              </li>
            </ul>

            <li>No Warranty</li>
            <ul>
              <li>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE APP, THE
                SERVICES, AND THE CONTENT ARE PROVIDED “AS IS” AND “AS
                AVAILABLE”, WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND,
                AND OVIO HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH
                RESPECT TO THE APP, THE SERVICES, AND/OR THE CONTENT, EITHER
                EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO,
                ANY WARRANTIES OF COMPLETENESS, ACCURACY, AVAILABILITY,
                TIMELINESS, USEFULNESS, SECURITY, RELIABILITY OR
                NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND ANY IMPLIED
                WARRANTIES OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OR OF
                FITNESS FOR A PARTICULAR PURPOSE.
              </li>
              <li>
                Some jurisdictions do not allow the exclusion of implied
                warranties or limitations on applicable statutory rights of a
                consumer, so the above exclusions and limitations may not apply
                to you in such jurisdictions.
              </li>
            </ul>

            <li>Limitation of Liability</li>
            <ul>
              <li>
                TO THE MAXIMUM EXTENT NOT PROHIBITED BY APPLICABLE LAW, IN NO
                EVENT SHALL OVIO, ITS DIRECTORS, OFFICERS, EMPLOYEES AND/OR
                AGENTS, BE LIABLE FOR (A) ANY DIRECT, INDIRECT, INCIDENTAL,
                SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES
                WHATSOEVER, (B) LOSS OF REVENUES, LOSS OF PROFITS, LOSS OF DATA,
                LOSS OF USE, LOSS OF GOODWILL, BUSINESS INTERRUPTION OR ANY
                OTHER LOSS, INJURY, CLAIM, LIABILITY, OR DAMAGE OF ANY KIND,
                RESULTING IN ANY WAY FROM (I) YOUR ACCESS TO, OR USE, INABILITY
                TO USE, OR RELIANCE ON THE APP, THE SERVICE, ANY CONTENT AND/OR
                EXTERNAL SITE, (II) ANY ERRORS, OMISSIONS OR OTHER INACCURACIES
                IN ANY CONTENT INCLUDED IN THE APP AND/OR IN ANY EXTERNAL SITE,
                (III) ANY OTHER MATTER RELATING TO THE APP, THE SERVICES, THE
                CONTENT AND/OR ANY EXTERNAL SITE, REGARDLESS OF THE THEORY OF
                LIABILITY (WHETHER BASED ON CONTRACT, TORT OR OTHERWISE), EVEN
                IF OVIO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND
                EVEN IF A REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL
                PURPOSE, OR FOR (C) ANY THIRD PARTY CLAIMS.
              </li>
              <li>
                IN NO EVENT SHALL THE TOTAL LIABILITY OF OVIO, ITS DIRECTORS,
                OFFICERS, EMPLOYEES AND/OR AGENTS, TO YOU AND/OR TO ANY THIRD
                PARTY, FOR ALL DAMAGES IN CONNECTION WITH THE APP, THE CONTENT
                AND THE SERVICES (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE
                LAW IN CASES INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF TEN
                U.S. DOLLARS (U.S. $10).
              </li>
              <li>
                ANY CAUSE OF ACTION BY YOU WITH RESPECT TO THE APP AND/OR
                SERVICE, MUST BE INSTITUTED WITHIN ONE (1) YEAR AFTER THE CAUSE
                OF ACTION AROSE.
              </li>
              <li>
                Some jurisdictions do not allow the exclusion or limitation of
                liability for personal injury, or of incidental or consequential
                damages, so the limitations above may not apply to you in such
                jurisdictions.
              </li>
            </ul>

            <li>Indemnity</li>
            <p>
              You shall indemnify and hold OviO and its directors, officers,
              employees, agents, partners and licensors, harmless from and
              against all damages, losses, and expenses of any kind (including
              reasonable legal fees and costs), related to any demand or claim
              brought against OviO by any User and/or any other third party, due
              to or arising out of your use of the App or the Services, your
              violation of these Terms, and/or your violation of any rights of
              another party.
            </p>

            <li>Ownership and Intellectual Property</li>
            <ul>
              <li>
                You hereby acknowledge that all rights, ownership, title and
                interest of the App, the Content (excluding External Sites) and
                the related patent rights, copyrights, trade secrets, trademarks
                and all other related intellectual property rights, are and
                shall remain the sole and exclusive property of OviO.
              </li>
              <li>
                All copyrights in and to the App, the Content and the OviO
                Credit are owned solely and exclusively by OviO, (and/or by its
                licensors), which reserves all its rights in law and equity with
                respect thereto.
              </li>
              <li>
                You are not granted any right and/or license, or ownership
                including any copyright, trademark or other intellectual
                property rights to the App or to any Content, other than as
                explicitly set forth in these Terms.
              </li>
              <li>
                Except as otherwise provided in the App or in these Terms, you
                may not use, download, upload, copy, print, display, perform,
                reproduce, reverse engineer, publish, modify, delete, add to,
                license, post, transmit or distribute any Content from this App
                in whole or in part, without our specific prior written
                permission.
              </li>
              <li>
                Any third-party trade or service marks present in Content are
                trade or service marks of their respective owners. Such Content
                may not be downloaded, copied, reproduced, distributed,
                transmitted, broadcasted, displayed, sold, licensed, or
                otherwise exploited for any other purpose whatsoever except as
                permitted in these Terms.
              </li>
              <li>
                OviO reserves all rights not expressly granted to you under
                these Terms.
              </li>
              <li>
                You agree that all trademarks, trade names, service marks,
                graphics, logos and other brand features used in connection with
                the App and the Services, are trademarks or registered
                trademarks of OviO (collectively, the “OviO Marks”). Nothing in
                these Terms gives you a right to use or display OviO Marks in
                any manner.
              </li>
              <li>
                THE USE OF THE APP, THE SERVICES, THE CONTENT AND/OR ANY PART
                THEREOF, OTHER THAN AS PERMITTED IN THESE TERMS, IS STRICTLY
                PROHIBITED AND INFRINGES ON THE INTELLECTUAL PROPERTY RIGHTS OF
                OVIO AND/OR OF OTHERS AND MAY SUBJECT YOU TO CIVIL AND CRIMINAL
                PENALTIES, INCLUDING POSSIBLE MONETARY DAMAGES FOR COPYRIGHT
                INFRINGEMENT.
              </li>
            </ul>

            <li>Submitting Information to Us</li>
            <ul>
              <li>
                By accessing and using the App or Services, you represent and
                warrant that you have read, and understand, our{" "}
                <Link to="/privacy-policy">privacy policy</Link> that describes
                how we handle personal data.
              </li>
              <li>
                We do not want to receive confidential or proprietary
                information from you through the App unless you have another
                written agreement with us related to the sharing of such
                information. Any information that we receive through the App
                will be deemed to be NON-CONFIDENTIAL.
              </li>
              <li>
                BY TRANSMITTING TO US INFORMATION VIA THE APP OR OTHERWISE
                THROUGH ELECTRONIC MEANS WITHOUT A WRITTEN AGREEMENT WITH US
                RELATING TO YOUR SUBMISSION, YOU UNDERSTAND AND AGREE THAT WE
                MAY USE THAT INFORMATION FOR ANY PURPOSE WHATSOEVER WITHOUT
                OBLIGATION TO YOU.
              </li>
            </ul>

            <li>Security</li>
            <ul>
              <li>
                Violations of system or network security may result in civil or
                criminal liability. You are prohibited from violating or
                attempting to violate the security of the App, including,
                without limitation, (i) accessing data not intended for you or
                logging on to a third party’s server or account that you are not
                authorized to access; or (ii) attempting to probe, scan or test
                the vulnerability of a system or network or to breach security
                or authentication measures without proper authorization (or
                succeeding in such an attempt).
              </li>
              <li>
                WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
                DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
                TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL
                IN CONNECTION WITH YOUR USE OF THE APP OR SERVICES.
              </li>
            </ul>

            <li>Termination</li>
            <ul>
              <li>
                To the fullest extent permitted by applicable law, we reserve
                the right, for any reason or for no reason, in our sole
                discretion and without notice to you, to revise the Services
                and/or the App and to terminate, change, suspend or discontinue
                any aspect of the App and/or the Services, including, but not
                limited to, the Content presented on the App as well as features
                and/or hours of availability of the App or the Services, and we
                will not be liable to you or to any third party for doing so. We
                may also impose rules for and limits on use of the App or
                restrict your access to part, or all, of the App without notice
                or penalty.
              </li>
              <li>
                These Terms shall be effective until terminated by OviO. Without
                derogating from any other right of OviO right to terminate these
                Terms, OviO, at its sole discretion, without notice to you, may
                terminate these Terms if you fail to comply with any of the
                provisions of these Terms, without prejudice to the right of
                OviO to be indemnified for its damages and for any other right
                and remedy.
              </li>
              <li>
                In the event that we suspend or terminate your use of the App
                and/or the Services, you acknowledge and agree that you will not
                receive any refund, including for any unused Game Coins and/or
                OviO Credit.
              </li>
              <li>
                In case these Terms shall terminate, for any reason, the
                provisions which by their nature would continue beyond
                termination (including, without limitation, the provisions of
                Sections 12, 13, 14, 15, 17, 18, 19, 20 and 21 shall survive
                such termination and shall continue to apply.
              </li>
              <li>
                Upon termination of these Terms, you shall cease all use of the
                App and/or the Services.
              </li>
            </ul>

            <li>Governing Law and Jurisdiction</li>
            <ul>
              <li>
                These Terms shall be governed and construed in accordance with
                the laws of the State of Israel, without reference to its
                conflicts of laws principles. The United Nations Convention on
                Contracts for the International Sale of Goods will not apply to
                these Terms.
              </li>
              <li>
                You hereby irrevocably submit to the exclusive jurisdiction of
                the competent courts in Tel Aviv, Israel, to resolve any dispute
                arising out of or pursuant to these Terms, and you hereby
                consent to the exclusive jurisdiction of and venue in such
                courts and waive any objection as to inconvenient forum.
                Notwithstanding the above, you agree that OviO shall still be
                allowed to apply for injunctive remedies in any jurisdiction.
              </li>
            </ul>

            <li>Unlawful activity</li>
            <ul>
              <li>
                You may not use this App or the Services, other than in
                accordance with the laws of the State of Israel and the
                jurisdiction from which you accessing and/or otherwise using the
                App or the Services.
              </li>
              <li>
                We reserve the right to investigate complaints or reported
                violations of these Terms and to take any action we deem
                appropriate, including but not limited to reporting any
                suspected unlawful activity to law enforcement officials,
                regulators, or other third parties and disclosing any
                information necessary or appropriate to such persons or
                entities.
              </li>
            </ul>

            <li>Miscellaneous</li>
            <ul>
              <li>
                Severability. In the event that any provision of these Terms is
                held to be invalid or unenforceable, that provision shall be
                construed, limited, modified or deleted, to the extent necessary
                to eliminate any invalidity or unenforceability, and the
                remaining provisions of these Terms remain in full force and
                effect.
              </li>
              <li>
                Waiver. No waiver on the part of OviO of any right under these
                Terms shall be effective unless in writing and signed by OviO’s
                duly authorized representative. No waiver on the part of OviO of
                any past or present right arising from any breach or failure to
                perform shall be deemed to be a waiver of any future right
                arising under these Terms.
              </li>
              <li>
                You may not assign, subcontract or otherwise transfer any of
                your rights and/or obligations under these Terms. OviO may
                assign and/or subcontract some or all of these Terms to any
                third party in connection with a merger, acquisition, sale of
                assets, by operation of law, or otherwise. These Terms shall be
                binding upon, and shall inure to the benefit of, the parties
                hereto and their respective successors and permitted assigns.
              </li>
              <li>
                No Third–Party Beneficiaries. These Terms do not create any
                obligation of OviO to any third parties, nor shall they be
                deemed to create any rights or causes of action on behalf of any
                third parties.
              </li>
              <li>
                Changes to these Terms. We may change these Terms from time to
                time, in our own reasonable discretion. We will provide notice
                in case we make substantial changes to these Terms, through the
                App or using the contact details that you provided upon
                registration. Such substantial changes will take effect seven
                (7) days after such notice was provided. All other changes to
                these Terms are effective immediately upon publication of the
                updated Terms on the App. Your continued use of the App and/or
                Services after the publication of the updated Terms will be
                deemed acceptance on your behalf of any and all such changes.
              </li>
            </ul>

            <li>Contact Information</li>
            <ul>
              <li>
                If you have any questions or comments regarding these Terms,
                please contact us at: support@ovio.gg
              </li>
            </ul>
          </ol>

          <p>This version was last updated on March 28, 2022.</p>
        </div>
      </TermsStyle>
      {!hideTitle && <Footer />}
    </div>
  );
}

export default Terms;
