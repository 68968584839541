import styled, { css } from 'styled-components';
import Glow from "assets/images/landing/glow.svg";

export const Section = styled.section`
    padding: 30px 60px;    
    
    @media (max-width: 768px) {
        padding: 10px;
    }    
`;

export const Row = styled.div`
    width: 1512px;    
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 600px) {
        flex-direction: column;        
    }
`;

export const Column = styled.div`
    margin: 0 50px;
    flex: 1;    
    max-width: 587px;

    @media (max-width: 600px) {
        width: 100%;
        order: ${props => props.order};
        margin: 0;
        div.button {
            text-align: center;
        }      
        h1 {
            text-align: center;
        }  
    }
`;
export const MegaTitle = styled.h1`
    padding-top: 10px;
    font-size: 60px;
    font-family: 'NunitoSans_800';
    color: white; 

    @media (max-width: 1024px) {   
        font-size: 30px;
    }

    @media (max-width: 736px) {   
        font-size: 45px;
    }

    @media (max-width: 600px) {   
        font-size: 24px;
        line-height:33px;
        max-width: 55%;
    }

    @media (max-width: 320px) {   
        font-size: 20px;
    }

    @media (max-width: 280px) {   
        font-size: 17px;
    }
`;

export const Title = styled.h1`
    font-size: 48px;
    font-family: 'NunitoSans_700';
    color: white; 

    @media (max-width: 600px) {   
        font-size: 24px;
        text-align: center;        
    }
`;

export const SubTitle = styled.h1`
    margin-top: -25px;
    font-size: 28px;
    font-family: 'NunitoSans_600';
    color: white; 

    @media (max-width: 600px) {   
        margin-top: 0;
        font-size: 12px;
    }
`;

export const Content = styled.p`
    font-size: 20px;
    font-family: 'NunitoSans_700';
    color: white; 
    text-align: left;

    ${props => props.light && css`
    font-family: 'NunitoSans_400';
    `}

    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

export const ContentImg = styled.img`
    max-width: 100%;
    height: auto;
`; 

export const GradiantBG = styled.div`
    background: linear-gradient(-45deg, #171D24,#303D4B, #212932, #1d252e );
	background-size: 1000% 1000%;
	animation: gradient 15s ease infinite;
    min-height:100vh;
`;

export const BottomBg = styled.div`    
    height: 100%;   
    max-width: 1400px;
    margin: 0 auto; 
`;

export const Separator = styled.div`
    height: 8px;
    border-radius : 20px;
    border: solid 1px transparent;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #2AF598, #009EFD);
    background-origin: border-box;
    background-clip: content-box, border-box;
    margin-top: 2%;
    width: 100%;
`;

export const GlowBG = styled.div`     
    background: url(${Glow}) no-repeat top right;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;

    @media (max-width: 600px) {
        background-image: url(${Glow});
        background-repeat: no-repeat;
        background-position: 150%;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
    }

    @media (max-width: 400px) {
        background-image: url(${Glow});
        background-repeat: no-repeat;
        background-position: 100px;
        -webkit-background-size: contain;
        -moz-background-size: contain;
        -o-background-size: contain;
        background-size: contain;
    }
`;

export const ContentWrapper = styled.div`    
    position: relative;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    @media (max-width: 1600px){
        overflow: hidden;
        margin-right: -55px;
        width: 100%;
    }

    @media (max-width: 1400px){
        overflow: hidden;
        width: 100%;
    }

    @media (max-width: 1024px){
        margin-right: 0px;
    }

    @media (max-width: 768px){
        margin-right: 0px;
    }

    @media (max-width: 420px){
        margin-right: 0px;
    }
`;