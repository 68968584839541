import styled from 'styled-components';



export const FooterWrapper = styled.div`
    display: flex;
    border-radius: 20px;
    background-color: #2A3643;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;    
    justify-content: center;  
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const FooterNav = styled.nav`
    ul {
        list-style: none;
        padding: 0;
    }
    ul li {
        color: white;
        font-family: 'NunitoSans_400';
        font-size: 16px;
        line-height: 32px;
    }
`;

export const SocialNav = styled.nav`
    ul {
        list-style: none;
        display: flex;
        flex-direction: row;   
        padding: 0;
    }
    li {
        width: 20px;
    }

    ul li {       
        padding: 0 40px;
    }  
    
    @media (max-width: 600px) {
        ul li {       
            padding: 0 20px;
        }
    }
`;

export const StayConnectedTitle = styled.p`
    color: white;
    font-size: 16px;
    line-height: 21px;
    font-family: 'NunitoSans_800';
    margin-bottom: 20px;
    margin-top: 20px
`;

export const GooglePlayTitle = styled.p`
    color: white;
    font-size: 16px;
    line-height: 21px;
    font-family: 'NunitoSans_700';
    margin-bottom: 20px;
    margin-top: 20px
`;

export const Seperator = styled.span`
    display: block;
    height: 24px;
    border: 1px solid #586C83;
`;

export const SmallText = styled.p`
    font-family: 'NunitoSans_400';
    font-size: 16px;
    color: white;
    margin-top: 20px;

    @media (max-width: 600px) {
        font-size: 11px;
    }
`;