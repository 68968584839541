import Footer from "pages/landing/footer/Footer.tsx";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { TermsStyle } from "./style";

function Privacy() {
  return (
    <div className="aboutBG">
      <TermsStyle>
        <div>
          <h1>Privacy Policy</h1>
          <h2>OviO Application Privacy Policy</h2>
          <p>
            OviO Gaming Ltd. (referred to as <b>“OviO”</b>, <b>“we”</b>,{" "}
            <b>“us”</b> or <b>“our”</b> in this Privacy Policy) respects your
            privacy and is committed to protecting your Personal Data. This
            Privacy Policy <b>(“Privacy Policy”)</b> is designed to inform you
            how we handle your personal data when you download and use our
            application <b>(“App”)</b> and notify you about your privacy rights
            and how the law protects such rights. To the extent that the
            European General Data Protection Regulation <b>(“GDPR”)</b> or the
            United Kingdom General Data Protection Regulation <b>(“UK GDPR”)</b>{" "}
            apply to Personal Data that we process in accordance with this
            Privacy Policy, we act as a ‘data controller’ with respect to such
            processing.
          </p>
          <p>
            <b>“Personal Data”</b> means any information about an individual
            from which that person can be identified. Capitalized terms used but
            not otherwise defined in this Privacy Policy shall have the meanings
            ascribed to such terms in the{" "}
            <Link to="/terms-of-use">Terms of Use</Link>.
          </p>
          <p>
            We encourage you to read this Privacy Policy carefully, and to
            regularly check this page to review any changes we might make to the
            terms of this Privacy Policy. This Privacy Policy supplements other
            policies we may provide as part of our interactions with you or
            policies you may have received from other controllers and is not
            intended to override such policies. 
          </p>
          <p>
            This Privacy Policy is provided in a layered format so you can click
            through to the specific areas set out below.  
          </p>
          <p>
            <ol>
              <li>
                <HashLink to="#1">The Data We Collect About You</HashLink>
              </li>
              <li>
                <HashLink to="#2">How We Collect Your Personal Data</HashLink>
              </li>
              <li>
                <HashLink to="#3">How We Use Your Personal Data</HashLink>
              </li>
              <li>
                <HashLink to="#4">
                  Sharing Your Personal Data with Third Parties
                </HashLink>
              </li>
              <li>
                <HashLink to="#5">International Transfers</HashLink>
              </li>
              <li>
                <HashLink to="#6">Data Security</HashLink>
              </li>
              <li>
                <HashLink to="#7">Data Retention</HashLink>
              </li>
              <li>
                <HashLink to="#8">Your Rights</HashLink>
              </li>
              <li>
                <HashLink to="#9">Accessibility</HashLink>
              </li>
              <li>
                <HashLink to="#10">Contact Details</HashLink>
              </li>
              <li>
                <HashLink to="#11">Changes to this Privacy Policy</HashLink>
              </li>
            </ol>
          </p>
          <br />
          <br />
          <br />
          <ol>
            <li id="1">The Data We Collect About You</li>
            <p>
              When you use this App, we may collect the following types of
              Personal Data on you:
            </p>
            <ul>
              <li>
                <b>Identity Data</b> includes user name and password.
              </li>
              <li>
                <b>Contact Data</b> includes email address and phone number.{" "}
              </li>
              <li>
                <b>Country Data</b> includes the country from which you use the
                App.{" "}
              </li>
              <li>
                <b>Usage Data</b> includes information about how you use or
                interact with our App (such as, when you entered the App, how
                long you stayed in the App and information on your in-app
                purchases).{" "}
              </li>
              <li>
                <b>Technical Data</b> includes information on your internet
                connection (such as your IP address and internet service
                provider name), on the device and software that you are using to
                access the App (e.g., your web browser type, mobile device type,
                and computer operating system).{" "}
              </li>
              <li>
                <b>Additional Inquiry Data</b> includes additional information
                that you may provide when you contact.
              </li>
            </ul>
            <p>
              We also collect, use and share aggregated data such as statistical
              data for any purpose. Such information does not reveal your
              identity, and therefore is not considered Personal Data. For
              example, we may aggregate your Usage Data to calculate the
              percentage of users accessing a specific App feature.
            </p>
            <p>
              If you register to the App via your Google, Facebook or Apple
              account, we may collect additional Identity Data, such as your
              first and last name, profile picture and other details received
              from such companies.
            </p>
            <p>
              <i>
                <b>Protecting the Privacy Rights of Third Parties</b>
              </i>
              <br />
              If you provide to us any Personal Data relating to others, you
              must make sure that you have permission to do so.
            </p>

            <li id="2">How We Collect Your Personal Data </li>
            <p>
               We use different methods to collect data from and about you
              including through:
            </p>
            <ul>
              <li>
                <b>Direct interactions. </b>You may provide us your Identity
                Data and Contact Data by entering your details in our App or by
                signing in with your Google, Facebook or Apple account. You may
                also provide Additional Inquiry Data to us when you contact us.{" "}
              </li>
              <li>
                <b>Automated technologies or interactions.</b> As you interact
                with our App, we automatically collect Technical Data and Usage
                Data. We collect this data by using server logs and other
                similar technologies.{" "}
              </li>
              <li>
                <b>Third parties or publicly available sources.</b> We may
                receive Technical Data and Usage Data about you from third
                parties, such as Usage Data and Technical Data that we may
                receive from analytics providers, and from advertising and
                marketing partners.{" "}
              </li>
            </ul>
            <p>
              You are not legally obligated to provide any Personal Data to
              OviO. Any Personal Data that you choose to provide to OviO, is
              provided at your own free will.
            </p>

            <li id="3">How We Use Your Personal Data  </li>
            <p>We will only use your Personal Data when permitted by law.</p>
            <p>
              We will commonly use your Personal Data in the following
              circumstances:
            </p>
            <ul>
              <li>
                Where the processing is necessary in connection with the
                performance of any contract we have entered into with you or
                where it is necessary for taking steps at your request prior to
                entering a contract.
              </li>
              <li>
                Where the processing is necessary for our legitimate interests
                (or those of a third party), and such legitimate interests are
                not overridden by your interests, fundamental rights, or
                freedoms. Legitimate Interest means our interest in managing and
                conducting our business.
              </li>
              <li>
                Where the processing is necessary for compliance with a legal or
                regulatory obligation.
              </li>
              <li>
                Where you have provided your consent for such processing. Such
                consent can be revoked at any time.
              </li>
            </ul>
            <p>
              <i>
                <b>Purposes for Which We Use Your Personal Data</b>
              </i>
              <br />
              Below is a description of the ways we use your Personal Data. To
              the extent that the GDPR and UK GDPR apply, we have mentioned the
              legal bases we rely on to use your Personal Data, and identified
              what our legitimate interests are where appropriate: We use your
              Identity Data and Contact Data to enter into a contract or to
              perform a contract with you. We also use your Usage Data for the
              performance of a contract with you (to provide our services to you
              via the App).
            </p>
            <ul>
              <li>
                We use your Contact Data and Additional Inquiry Data to respond
                to your requests or questions and to manage our relationship
                with you, including by notifying you about changes to our terms
                or Privacy Policy. We do so for the performance of a contract
                with you, if it is necessary to comply with a legal obligation
                or if it is necessary for our legitimate interests
              </li>
              <li>
                We use your Usage Data, Technical Data, Country Data and Contact
                Data for marketing purposes and to deliver relevant and
                personalized content to you, as well as service messages (for
                example, for security purposes) including via email or text
                message (SMS), and to measure or understand the effectiveness of
                our marketing efforts. We do so where it is necessary for our
                legitimate interests (to grow our business, to inform our
                marketing strategy and to maintain and secure the Services); or
                if we have obtained your prior consent (where laws require
                consent for such communications).
              </li>
              <li>
                In order to facilitate User Transfers, we may request your
                consent to access your contact list to receive Identity Data and
                Contact Data of the User to whom you wish to transfer OviO
                Credit and/or Game Coins.
              </li>
              <li>
                We use your Usage Data and Technical Data to improve our App,
                services, marketing, customer relationships and experiences,
                including by personalizing and customizing our content. We do so
                where it is necessary for our legitimate interests (to keep our
                App updated and relevant, to develop our business and to inform
                our marketing strategy).
              </li>
              <li>
                In the event of a legal dispute between you (and/or a party on
                your behalf) to us (and/or a party on our behalf), we may use
                your Personal Data to the extent necessary in connection with
                handling such dispute. We do so where it is necessary for our
                legitimate interests (to defend and enforce our legal rights).
              </li>
              <li>
                We may also use your Personal Data as: (a) required by subpoena,
                law, or other legal process; (b) necessary to assist law
                enforcement officials or government enforcement agencies; (c)
                necessary to investigate violations of or otherwise enforce our
                terms of use, to maintain security of the App and prevent
                fraudulent or illegal activity; (d) necessary to protect us from
                legal action or claims from third parties, including you and/or
                other users or members; or (e) necessary to protect the legal
                rights, personal/real property, or personal safety of our
                company, users, employees, and affiliates. We do so based on our
                legitimate interest (to defend and enforce our legal rights and
                to protect and ensure the continuous operation of our App).
              </li>
            </ul>
            <p>
              Note that we may process your Personal Data for more than one
              lawful ground depending on the specific purpose for which we are
              using your data.
            </p>
            <p>
              <b>
                <i>Promotional Offers</i>
              </b>
              <br></br>
              You will receive marketing communications from us if you have
              agreed to receive such communications or otherwise requested
              information from us and you have not opted out of receiving
              marketing communications.
            </p>
            <p>
              You can change your contact preferences at any time by sending us
              an email with your request to the email that appears in the{" "}
              <HashLink to="#10">Contact Details</HashLink> section below. If
              you wish to stop receiving marketing communications from us, you
              may ask us to stop sending you marketing communications at any
              time by following the opt-out link on any marketing message sent
              to you or by <HashLink to="/#contact"> contacting us</HashLink>.
            </p>
            <p>
              <b>
                <i>Notifications</i>
              </b>
              <br />
              We may send notifications to your mobile device (push
              notifications) or email regarding your activity, or activity
              relating to you, in the App including text messages and push
              notifications. At any time, you can manage your push notification
              preferences or deactivate these notifications by turning off the
              notification settings in the device settings of your mobile
              device. You may ask us to stop sending email notification to you
              at any time by <HashLink to="/#contact"> contacting us</HashLink>,
              however you will not be able to opt-out of receiving service
              messages from us, including legal and security notices (such as
              two-factor authentication messages).
            </p>
            <p>
              <b>
                <i>Third-Party Links</i>
              </b>
              <br />
              The App may include links to third-party applications, websites
              and plug-ins. Clicking on those links or interacting with such
              content may allow your data to be shared with the relevant
              third-party. We do not control these third-party applications,
              websites and plug-ins and are not responsible for their privacy
              statements; we therefore recommend that you read the applicable
              privacy policies.
            </p>
            <p>
              <b>
                <i>Children under 13</i>
              </b>
              <br />
              In order to use the App, you must be at least 13 years of age, or
              older, if otherwise required by the laws of the country where you
              reside. Even though our App is not designed for use by anyone
              under the age of 13, we realize that a child under the age of 13
              may attempt to access our App. We do not knowingly collect
              Personal Data from children under the age of 13.
            </p>
            <p>
              If you are not 18 years old or older, you must review this Privacy
              Policy with your parent or legal guardian. If you are a parent or
              legal guardian and have concerns about your child’s privacy, or if
              you believe that your child may have provided us with their
              Personal Data, please contact us using the{" "}
              <HashLink to="#10">Contact Details</HashLink> below. When you
              approach us as a parent or legal guardian, we may request that you
              provide certain information needed to confirm your identity, in
              order to prevent malicious removal of account information.
            </p>

            <li id="4">Sharing Your Personal Data with Third Parties</li>
            <p>
              We share Personal Data with third party service providers that
              provide us services in connection with the purposes listed above.
              For example, we may share your Usage Data and Technical Data with
              analytics providers and other vendors that assist us in
              maintaining the App. We may also share your Identity Data and
              Contact Data, with email and marketing service providers.
            </p>
            <p>
              In addition, we will share Usage Data regarding your in-app
              purchases with providers of Games for which you choose to utilize
              your OviO Credit or Game Coins via the App. We may also share your
              Personal Data with legal and regulatory authorities to the extent
              required by applicable law our professional advisors (e.g.
              lawyers, accountants) to the extent necessary for the provision of
              their services to us; and third parties to whom we may choose to
              sell, transfer, or merge our business (or parts thereof) or our
              assets, or parties whose business we wish to acquire.
            </p>

            <li id="5">International Transfers  </li>
            <p>
              We may transfer your Personal Data to countries outside of the
              country in which you reside (including outside of the European
              Economic Area (EEA) or the United Kingdom (UK)), that do not
              provide the same level of data protection as the country in which
              you reside and are not recognized by the European Commission
              and/or the applicable UK authority as providing an adequate level
              of data protection.
            </p>
            <p>
              Our transfers of Personal Data outside of the EEA or the UK are
              done for the performance of a contract or implementation of
              pre-contractual relations with you, based on your consent, or
              subject to safeguards that ensure the protection of your Personal
              Data, such as standard contractual clauses approved by the
              European Commission.
            </p>
            <li id="6">Data Security</li>
            <p>
              We have put in place appropriate security measures to prevent your
              Personal Data from accidental or unlawful loss, use, alteration or
              disclosure. We and the third parties who provide services for us,
              also maintain technical and physical safeguards to protect your
              Personal Data.
            </p>
            <p>
              We limit access to your Personal Data to those employees, agents,
              contractors and other third parties who have a need to know. We
              require them to only process your Personal Data on our
              instructions and as allowed by applicable law.
            </p>
            <p>
              Unfortunately, we cannot guarantee against the loss or misuse of
              your Personal Data or secure data transmission over the Internet
              because of its nature.
            </p>

            <li id="7">Data Retention</li>
            <p>
              We retain your Personal Data for as long as necessary to fulfill
              the purposes we collected it for, including for legal or reporting
              requirements.{" "}
            </p>
            <p>
              In order to determine the appropriate retention period for
              Personal Data, we consider the following criteria: the volume,
              nature, and sensitivity of the Personal Data, the potential risk
              of harm from unauthorized use or disclosure of Personal Data, the
              purposes for which we collect and process Personal Data, the
              timeframe during which we may require such Personal Data in
              connection with potential litigation and applicable legal
              requirements.{" "}
            </p>

            <li id="8">Your Rights</li>
            <p>
              Certain privacy laws, including the GDPR and UK GDPR, provide
              users with rights related to their Personal Data. To the extent
              that such laws apply to your Personal Data, you may have the
              following rights:
            </p>

            <ul>
              <li>
                <b>Request access.</b> This means that you may receive a copy of
                the Personal Data we hold about you.
              </li>
              <li>
                <b>Request correction.</b> This means that you may ask us to
                correct or complete inaccurate or incomplete data that we hold
                about you.{" "}
              </li>
              <li>
                <b>Request erasure.</b> This means that you may ask us to delete
                or remove Personal Data that we hold about you. If we are unable
                to comply with such request, we will notify you of the specific
                legal reasons for our decision.{" "}
              </li>
              <li>
                <b>Object to processing.</b> This means that you may object to
                the processing of your Personal Data where it is used for direct
                marketing purposes, or where we are relying on a legitimate
                interest and you feel that such interest is overridden by your
                rights and freedoms.
              </li>
              <li>
                <b>Request restriction of processing. </b>This means that you
                may ask us to suspend the processing of your Personal Data if
                you believe that such data is inaccurate, if our processing is
                unlawful or if we no longer need to process such data for a
                particular purpose, but you need us to continue to hold the
                data.
              </li>
              <li>
                <b>Data Portability.</b> This means that you may request that we
                transfer your Personal Data to you or to a third party. This
                right applies where we have obtained and processed the data from
                you based on your consent or where we used the information to
                perform a contract with you.
              </li>
              <li>
                <b>Withdraw consent at any time. </b>This means that you may
                withdraw your consent where we are processing your Personal Data
                based on your consent.
              </li>
            </ul>
            <p>
              If you wish to exercise any of the rights set out above, please{" "}
              <HashLink to="/#contact"> contact us</HashLink>.
            </p>
            <p>
              <b>
                <i>What We May Need From You  </i>
              </b>
              <br />
              When you approach us with a request to exercise one of your
              rights, we may request that you provide us with information needed
              to confirm your identity as a security measure to ensure that your
              Personal Data is not disclosed to any person who has no right to
              receive it.
            </p>
            <p>
              <b>
                <i>Time Limit to Respond  </i>
              </b>
              <br />
              We try to respond to all legitimate requests within one month or
              less, if required by law. Where it takes us more than one month to
              respond (for example, where your request is particularly
              complicated or where you have made a number of requests) we will
              notify you and keep you updated.
            </p>

            <li id="9">Accessibility</li>
            <p>
              If you have a disability and would like to access this policy in
              an alternative format, please contact us via our Contact Details
              listed below.
            </p>

            <li id="10">Contact Details  </li>
            <p>
              If you have any questions or concerns, please contact us at: 
              <br />
              Email: <a href="mailto:Support@ovio.gg ">Support@ovio.gg</a>
              <br />
              Postal address: Arik Einstein 3 st. floor 8, Herzliya, Israel.
              <p>
                You have the right to make a complaint at any time to data
                protection authorities. We would, however, appreciate the chance
                to address your concerns before you approach a data protection
                authority, so please contact us in the first instance.
              </p>
            </p>

            <li id="11">Changes to this Privacy Policy </li>
            <p>
              We reserve the right to change this Privacy Policy at any time. If
              we do so, we will post an updated version of this Policy so that
              our users and customers are always aware of what information we
              collect, use, and disclose. Your continued use of our App and
              services after any change to this Privacy Policy will constitute
              your acceptance of such change.
            </p>
          </ol>
          <p>This version was last updated on January 13th, 2022. </p>
        </div>
      </TermsStyle>
      <Footer />
    </div>
  );
}

export default Privacy;
