import { Fields } from "./types.tsx";
const { NAME, EMAIL, PHONE, MESSAGE } = Fields;

export const FIELDS = [
  {
    placeholder: "Name*",
    id: NAME,
  },
  {
    placeholder: "E-mail*",
    id: EMAIL,
  },
  {
    placeholder: "Phone",
    id: PHONE,
  },
  {
    placeholder: "Your Message",
    id: MESSAGE,
  },
];

export const OPTIONAL_FIELDS_INVALID = [NAME, EMAIL];
