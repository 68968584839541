export const WALLET_STEPS = [
  {
    title: "CREATION",
    srcs: [
      "createWalletStep1.png",
      "createWalletStep2.png",
      "createWalletStep3.png",
    ],
  },
  {
    title: "CONNECTION",
    srcs: ["connectStep1.png", "connectStep2.png", "connectStep3.png"],
  },
];
