import { useState } from "react";
import { HeaderButtons } from "./types.tsx";
import { HEADER_BUTTONS_DATA } from "./constants.tsx";
import {
  HeaderContainer,
  OvioLogo,
  Buttons,
  Button,
  LogoAndButtons,
  MenuImage,
} from "./styles.tsx";
import ovioLogo from "assets/images/ovioLogo.png";
import { useNavigate } from "react-router-dom";
import { windowHeight } from "constants/windowSize.tsx";
import { isMobileDevice } from "constants/windowSize.tsx";
import { useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const { HOME, OUR_PRODUCTS, ABOUT_US, CONTACT_US } = HeaderButtons;
  const headerButtonsKeys = Object.keys(HEADER_BUTTONS_DATA);
  const location = useLocation();
  const currentPath = location.pathname;
  const isHomePage = currentPath === "/";
  const [activeComponentInHome, setActiveComponentInHome] = useState(HOME);
  const [shouldShowMenu, setShouldShowMenu] = useState(false);

  const getInitialSelectedButton = () => {
    switch (currentPath) {
      case "/":
        return HOME;
      case "/about":
        return ABOUT_US;
    }
  };

  const [selectedButton, setSelectedButton] = useState(
    getInitialSelectedButton()
  );

  const handleScroll = () => {
    if (isHomePage) {
      const contactUsTop = document.getElementById("contactUs")?.offsetTop || 0;
      const ourProductsTop =
        document.getElementById("ourProducts")?.offsetTop || 0;
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      const isScrollContactUs =
        scrollPosition > contactUsTop - windowHeight * 0.1;

      if (scrollPosition >= ourProductsTop && !isScrollContactUs) {
        setActiveComponentInHome(OUR_PRODUCTS);
      } else if (isScrollContactUs) {
        setActiveComponentInHome(CONTACT_US);
      } else {
        setActiveComponentInHome(HOME);
      }
    }
  };

  window.addEventListener("scroll", handleScroll);

  const handleClick = async (
    button: HeaderButtons,
    redirectPage: string,
    scrollToComponent: string
  ) => {
    if (![HOME, CONTACT_US, OUR_PRODUCTS].includes(button)) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }

    if (button === ABOUT_US) {
      setActiveComponentInHome(HOME);
    }

    setSelectedButton(button);

    if (redirectPage) {
      await navigate(redirectPage);
    }

    if (scrollToComponent) {
      const element = document.getElementById(scrollToComponent);
      if (element) {
        if (scrollToComponent === "ourProducts") {
          const scrollPosition =
            element.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: scrollPosition - windowHeight * 0.06,
            behavior: "smooth",
          });
        } else {
          element?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
    if (shouldShowMenu) {
      setShouldShowMenu(false);
    }
  };

  const renderHeaderButtons = () => {
    return (
      <Buttons>
        {headerButtonsKeys.map((button) => {
          const buttonData = HEADER_BUTTONS_DATA[button as HeaderButtons];
          return (
            <Button
              isSelectedButton={
                selectedButton === HOME
                  ? activeComponentInHome === button
                  : selectedButton === button
              }
              onClick={() =>
                handleClick(
                  button as HeaderButtons,
                  buttonData?.redirectPage,
                  buttonData?.scrollToComponent
                )
              }
            >
              {buttonData.text}
            </Button>
          );
        })}
      </Buttons>
    );
  };

  const renderMenuIcon = () => (
    <MenuImage
      onClick={() => setShouldShowMenu(!shouldShowMenu)}
      src={require(shouldShowMenu
        ? "../../assets/images/header/close.png"
        : "../../assets/images/header/menu.png")}
    />
  );

  const renderMenu = () => {
    return renderHeaderButtons();
  };

  return (
    <HeaderContainer>
      <LogoAndButtons>
        <a href="/">
          <OvioLogo src={ovioLogo} />
        </a>
        {isMobileDevice ? renderMenuIcon() : renderHeaderButtons()}
      </LogoAndButtons>
      {shouldShowMenu && renderMenu()}
    </HeaderContainer>
  );
};

export default Header;
