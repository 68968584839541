export enum Fields {
  NAME = "name",
  EMAIL = "email",
  PHONE = "phone",
  MESSAGE = "message",
}

export interface CheckFieldsInvalid {
  name: Fields.NAME | Fields.EMAIL;
  value: string;
}
